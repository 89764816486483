import { Col, Container, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import Footer from './Footer';
import Link from './Link';
import { launchLink } from '../Routes';

class FooterLarge extends Component {

    render () {
        return (
            <div>
                <section className="container-fluid bg-gradient2 pb-5">
                    <div className="bg-pattern">
                        <Container>
                            <Row>
                                <Col className="col-lg-8 mt-5">
                                    <h5 className="mt-5 mb-5">
                                        Our fund provides investors with <strong>calculated risk exposure</strong> to a full range of blockchain-based digital assets and alternative yield generating strategies and protocols.
                                    </h5>
                                    <p>
                                        To learn more about Caiman Fund, or to explore investments opportunities, please <Link
                                        to='/contact'>contact us</Link> today.
                                    </p>
                                    <p className="mt-5">
                                        <Link
                                            className="btn btn-outline-primary mb-5 btn-white"
                                            to={launchLink()}>
                                            Launch the app
                                        </Link>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}

export default FooterLarge;
