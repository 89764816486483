import { Col, Container, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import Footer from './Footer';

class FooterSubscribe extends Component {

    render () {
        return (
            <div>
                <section className="container-fluid bg-dark pb-5">
                    <div className="bg-pattern text-light text-center">
                        <Container>
                            <Row>
                                <Col className="mt-5">
                                    <div className="cf-footer-subscribe">
                                        <h4 className="mt-5 mb-5">
                                            Stay in the loop!
                                        </h4>
                                        <p>
                                            Subscribe to our newsletter and get thew latest product updates,
                                            exclusive facts and news.
                                        </p>
                                        <div id="mc_embed_signup">
                                            <form
                                                action="https://fund.us5.list-manage.com/subscribe/post?u=fbd94aca31dcfb0280a15850e&amp;id=b736c68d42"
                                                method="post"
                                                id="mc-embedded-subscribe-form2"
                                                name="mc-embedded-subscribe-form"
                                                className="validate"
                                                target="_blank">
                                                <div
                                                    id="mc_embed_signup_scroll">
                                                    <label
                                                        forhtml="mce-EMAIL">Email</label>
                                                    <div
                                                        className="input-group mb-4 pb-2">
                                                        <input type="email"
                                                               name="EMAIL"
                                                               required={true}
                                                               className="required email form-control"
                                                               id="mce-EMAIL"/>
                                                        <input
                                                            type="submit"
                                                            value="Subscribe"
                                                            name="subscribe"
                                                            id="mc-embedded-subscribe"
                                                            className="form-control btn btn-primary"/>
                                                    </div>
                                                    <div id="mce-responses"
                                                         className="clear">
                                                        <div
                                                            className="response"
                                                            id="mce-error-response"
                                                            style={{display: 'none'}}>&nbsp;</div>
                                                        <div
                                                            className="response"
                                                            id="mce-success-response"
                                                            style={{display: 'none'}}>&nbsp;</div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            left: '-5000px'
                                                        }}
                                                        aria-hidden="true">
                                                        <input
                                                            type="text"
                                                            name="b_fbd94aca31dcfb0280a15850e_b736c68d42"
                                                            tabIndex="-1"/>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}

export default FooterSubscribe;
