import { Card, Col, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';
import LoadingContent from './LoadingContent';
import Message from './Message';
import Link from './Link';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Funds from '../Funds';
import { tokensToString } from '../Tokens';

const CHART_URL = 'https://caiman.fund/api/chart';

const CURRENCY_NAMES = {
    'ETH': 'Ethereum',
    'BTC': 'Bitcoin',
    'LINK': 'Chainlink',
    'DOT': 'Polkadot',
    'ADA': 'Cardano',
    'RUNE': 'THORChain',
    'MATIC': 'Polygon',
};

const CHART_OPTIONS = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
        padding: 0,
    },
    scales: {
        y: {
            stacked: true,
            display: false,
            beginAtZero: true,
            grid: {
                display: false,
                borderColor: 'transparent'
            },
        },
        x: {
            display: false,
            type: 'time',
            grid: {
                display: false,
                borderColor: 'transparent',
            },
            ticks: {
                color: 'rgba(71, 71, 71, 0.3)',
                font: {
                    size: 14,
                    weight: 800,
                },
                defaultFontSize: false,
                autoSkip: true,
                maxTicksLimit: 3,
                maxRotation: 0,
                minRotation: 0
            }
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    let label = ' ' + context.dataset.label + '  ';
                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }).format(context.parsed.y);
                    }
                    return label;
                },
                // title: function () {}
            }
        }
    }
};

class PortfolioInvestmentExpanded extends Component {

    constructor (props) {
        super(props);

        this.state = {
            data: {},
            tokens: props.tokens || [],
            funds: window.cffunds || Funds || [],
            fundData: window.cffunddata || {},
            zoom: 'all',
            stage: 'loading'
        };
        this.zoomChange.bind(this);
    }

    componentDidMount () {
        Chart.register({
            id: 'hover',
            afterDraw: function (chart, easing) {
                // window.tmp = chart;
                if (
                    chart &&
                    typeof chart !== 'undefined' &&
                    typeof chart.tooltip !== 'undefined' &&
                    chart.tooltip._active &&
                    chart.tooltip._active.length &&
                    chart.config.type === 'line'
                ) {
                    const activePoint = chart.tooltip._active[0];
                    window.activePoint = activePoint;
                    const ctx = chart.ctx;
                    const x = activePoint.element.x;
                    const topY = chart.scales['y'].top;
                    const bottomY = chart.scales['y'].bottom;
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = 'rgba(0,0,0,.5)';
                    ctx.stroke();
                    ctx.restore();
                }
            }
        });
        this.getData();
    }

    componentDidUpdate () {
        const {tokens} = this.state;
        if (!this.arrEq(tokens, this.props.tokens || [])) {
            console.log('token change', this.props.tokens);
            this.setState({
                tokens: this.props.tokens,
                stage: 'loading'
            }, this.getData);
        }
    }

    date (str) {
        if (str) {
            let d = new Date(str);
            let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
            let mo = new Intl.DateTimeFormat('en', {month: 'short'}).format(d);
            let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
            return `${mo} ${da}, ${ye}`;
        }
        return '';
    }

    arrEq (a, b) {
        // if (a !== b) {
        //     return false;
        // }
        return JSON.stringify(a) === JSON.stringify(b);
    }

    async getData (z) {
        const {tokens, zoom} = this.state;
        if (tokens.length === 0) {
            return;
        }

        if (typeof z == 'undefined') {
            z = zoom;
        }
        const url = [CHART_URL, z, tokensToString(tokens)].join('/');
        console.log('Investment FETCH', url);
        let data = await fetch(url)
            .then(response => response.json());

        this.setState({
            data,
            zoom: z,
            stage: 'ready'
        }, () => {
            // Apply color gradient to canvases
            if (typeof data.datasets !== 'undefined') {
                let ctx = document.getElementById('canvas');
                for (let d = 0; d < data.datasets.length; d++) {
                    if (ctx) {
                        let gradient = ctx.getContext('2d').createLinearGradient(0, 0, 0, 330 + (d * 10));
                        gradient.addColorStop(0, 'rgba(' + Math.min(255, 84 - (d * 5)) + ',255,' + Math.min(255, 195 + (d * 5)) + ',1)');
                        gradient.addColorStop(1, 'rgba(' + Math.min(255, 84 - (d * 5)) + ',255,' + Math.min(255, 195 + (d * 5)) + ',0)');
                        data.datasets[d].backgroundColor = gradient;
                    }
                    else {
                        data.datasets[d].backgroundColor = 'rgba(84,255,195,1)';
                    }
                    data.datasets[d].borderColor = 'rgb(' + Math.min(255, (84 - (d * 5))) + ',255,' + Math.min(255, (194 + (d * 5))) + ')';
                    data.datasets[d].fill = true;
                    data.datasets[d].tension = .3;
                    data.datasets[d].borderWidth = 2.5;
                    data.datasets[d].pointRadius = 0;
                    data.datasets[d].pointHitRadius = 20;
                }
            }
        });
    }

    render () {
        let {data, tokens, funds, stage} = this.state;

        if (stage === 'loading') {
            return (<LoadingContent/>);
        }

        if (!tokens.length) {
            return (
                <Message text="Please log in to view your position"/>
            );
        }

        let token = tokens[0];

        let fund = funds[token.fundId] || {};

        let values = {};
        if (typeof data.meta !== 'undefined') {
            values = data.meta.values;
        }

        let times = {};
        if (typeof data.meta !== 'undefined') {
            times = data.meta.times;
        }

        let label = 'loading...';
        if (typeof data.datasets !== 'undefined') {
            label = data.datasets[0].label ?? '';
        }

        let coins = [];
        if (typeof data.meta !== 'undefined') {
            for (const c in data.meta.coins) {
                coins.push(
                    <Row key={c}>
                        <div className="cf-investment-coin">
                            <span className="float-start crypto-icon">
                                <img
                                    src={'/img/crypto/' + c.toLowerCase() + '.svg'}
                                    alt={CURRENCY_NAMES[c] || c || ''}
                                    width={36} height={36}/>
                            </span>
                            <div className="px-5">
                                <span
                                    className="currency">{CURRENCY_NAMES[c] || c || ''}</span>
                                <span className="currency-name">{c}</span>
                            </div>
                            <span
                                className="float-end percentage">{data.meta.coins[c]}</span>
                        </div>
                    </Row>
                );
            }
        }

        let chartStyle = {};
        if (stage === 'zooming') {
            chartStyle = {opacity: 0};
        }
        let className = 'col-md-8 col-12 mt-4 mb-5';
        let title = 'Your Position';

        // let tooltips = false;
        const dismiss = (
            <span className="dismiss"
                  onClick={(e) => {
                      e.target.parentElement.style.display = 'none';
                      e.target.parentElement.previousElementSibling.style.display = 'none';
                  }}
            >
                    Dismiss
                    </span>
        );
        const tooltip1 = props => (
            <Tooltip {...props}>
                <div className="cf-investment-header">
                    Your Assets
                </div>
                <p>
                    This represents the estimated current percentage return for the fund. This amount will fluctuate as the market trends shift and over time. All percentages are approximated and may not include all data points.
                </p>
                {dismiss}
            </Tooltip>
        );
        const tooltip2 = props => (
            <Tooltip {...props}>
                <div className="cf-investment-header">
                    Overview
                </div>
                <p>
                    Quickly see at a glance statistics related to your specific position in the fund. All percentages are approximated and may not include all data points.
                </p>
                {dismiss}
            </Tooltip>
        );
        const tooltip3 = props => (
            <Tooltip {...props} className="tooltip-hide-on-mobile">
                <div className="cf-investment-header">
                    Assets
                </div>
                <p>
                    Review which assets or tokens make up a specific fund as well as their respective ratios within the fund. Ratios may not be exact as fund positions are fluid and managed consistently to maintain balance.
                </p>
                {dismiss}
            </Tooltip>
        );
        if (this.props.demo) {
            className += ' cf-demo-expanded';
            title = 'Demonstration Position';
        }

        let plCardTop = '';
        let plCard = '';
        if (parseInt(values.dailyPL || '0') >= parseInt(values.totalPL || '0')) {
            plCard = (
                <Card className="mb-4">
                    <div className="card-body">
                        <div
                            className="cf-investment-header">Today's P/L
                        </div>
                        <p className="card-text cf-investment-stats">
                            <span
                                className="cf-investment-usd">
                                {values.dailyChange || ''}
                            </span>
                            <br/>
                            <span
                                className={values.dailyPLClass || ''}>
                                {values.dailyPL || ''}
                            </span>
                        </p>
                    </div>
                </Card>
            );
            plCardTop = (
                <span className={values.dailyPLClass || ''}>
                    {values.dailyPL || ''}
                </span>
            );
        }
        else {
            plCard = (
                <Card className="mb-4">
                    <div className="card-body">
                        <div
                            className="cf-investment-header">Total P/L
                        </div>
                        <p className="card-text cf-investment-stats">
                            <span
                                className="cf-investment-usd">
                                {values.totalChange || ''}
                            </span>
                            <br/>
                            <span
                                className={values.totalPLClass || ''}>
                                {values.totalPL || ''}
                            </span>
                        </p>
                    </div>
                </Card>
            );
            plCardTop = (
                <span className={values.totalPLClass || ''}>
                    {values.totalPL || ''}
                </span>
            );
        }
        return (
            <Row>
                <Col className={className}>
                    <div>
                        <div
                            className="cf-investment-header">{title} • {label}
                        </div>
                        <div
                            className="cf-investment-usd-lg">
                            {values.finalValue || ''}
                        </div>
                        <div
                            className="cf-investment-stats">
                            <OverlayTrigger placement="bottom-start"
                                            overlay={tooltip1}
                                            show={this.props.demo || false}>
                                {plCardTop}
                            </OverlayTrigger>
                            <span className="pull-end">
                                        {this.date(times.endTime || null)}
                                    </span>
                        </div>
                    </div>
                    <div className="cf-investment-chart">
                        <Line id="canvas"
                              style={chartStyle}
                              data={data}
                              options={CHART_OPTIONS}
                        />
                    </div>
                    {this.zoomLinks()}
                    <div className="cf-investment-overview">
                        <Row className="mt-4 mb-3">
                            <small
                                className="text-muted"><strong>Note:</strong> Approximate returns are based on the {values.investmentValue} deposit made at the beginning of the subscription period.</small>
                        </Row>
                        <Row className="row mt-5">
                            <Col className="col-6">
                                <Card className="pb-2 mb-4">
                                    <div className="card-body">
                                        <div
                                            className="cf-investment-header">Initial Asset Value
                                        </div>
                                        <p className="card-text cf-investment-stats">
                                                <span
                                                    className="cf-investment-usd">
                                                    {values.investmentValue || ''}
                                                </span>
                                            <br/>
                                            <span className="pull-end">
                                                    {this.date(times.startTime || null)}
                                                </span>
                                        </p>
                                    </div>
                                </Card>
                            </Col>
                            <Col className="col-6">
                                <OverlayTrigger placement="top"
                                                overlay={tooltip2}
                                                className="tooltip-hide-on-mobile"
                                                show={this.props.demo || false}>
                                    {plCard}
                                </OverlayTrigger>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <p className="fs-6">
                                <small>
                                    Questions? <Link
                                    to="/contact">Contact us</Link>
                                </small>
                            </p>
                        </Row>
                    </div>
                </Col>
                <Col className="col-md-4 col-12 cf-fund-sidebar bg-white">
                    <h5 className="pt-4 mt-4">{fund.name || ''}</h5>
                    <small
                        className="text-muted cf-investment-header-sm">All-time-performance: {fund.atp || ''}</small>
                    <small
                        className="text-muted cf-investment-header-sm">Min. Deposit: {fund.min_investment || ''}</small>
                    <p className="mt-5">{fund.description || ''}</p>
                    <span
                        className="cf-investment-header-2 mt-2">Balance of coins</span>
                    <OverlayTrigger placement="top-end"
                                    overlay={tooltip3}
                                    show={this.props.demo || false}>
                        <div className="cf-investment-coins">
                            {coins}
                        </div>
                    </OverlayTrigger>
                </Col>
            </Row>
        );
    }

    zoomChange = (e, data) => {
        this.setState({stage: 'zooming'}, () => {
            this.getData(e.target.dataset.level);
        });
    };

    zoomLinks () {
        const {zoom} = this.state;
        // @todo - Hide or deactivate zoom levels that do not make sense.
        // '1d',
        const levels = ['1w', '1m', '3m', '6m', '8m', '1y', 'all'];
        let links = [];
        for (let l = 0; l < levels.length; l++) {
            let className = 'btn btn-sm';
            if (levels[l] === zoom) {
                className += ' active';
            }
            links.push(
                <span className={className}
                      key={l}
                      onClick={this.zoomChange}
                      data-level={levels[l]}>
                    {levels[l]}
                </span>
            );
        }
        return (
            <div
                className="cf-investment-chart-zoom d-flex justify-content-between text-uppercase">
                {links}
            </div>
        );
    }
}

export default PortfolioInvestmentExpanded;
