import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import getWalletConnect from '../getWalletConnect';
import CaimanFund from '../contracts/CaimanFundV1.json';
import Link from './Link';
import Tilt from 'react-parallax-tilt';

const READY_STATE = 'ready';
const FUND_MIN = 1;
const FUND_MAX = 10;
const SOURCE_MIN = 0;
const SOURCE_MAX = 10;
const TRANSACTION_LINKS = 'https://etherscan.io/tx/';
const TOKEN_SYMBOL = 'CFD';
const TOKEN_DECIMALS = 0;
const TOKEN_IMAGE = 'https://caiman.fund/img/token.png';
const ETHPRICE_API = 'https://caiman.fund/api/ethprice';
const EXTERNAL_WALLET_MIN_LENGTH = 25;
const EXTERNAL_WALLET_MAX_LENGTH = 39;
const EXTERNAL_WALLET_PATTERNS = {
    'btc': '^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$'
};

class DepositForm extends Component {

    constructor (props) {
        super(props);
        this.state = {
            // Contract requirements
            web3: null,
            networkId: null,
            accounts: window.cfweb3accounts || JSON.parse(window.sessionStorage.getItem('cfweb3accounts')) || [],
            contract: null,
            funds: {},
            sources: {},
            stage: 'connecting',
            balances: {},
            allowances: {},
            allowanceNeeded: '0',
            // Default form values
            submit: '',
            placeholder: '',
            fundId: '',
            fundName: '',
            sourceId: '',
            externalSource: '',
            externalPattern: '[a-zA-Z0-9]{23,64}',
            externalDestination: '',
            isExternal: false,
            amount: '',
            amountPattern: '[0-9,]+',
            amountBN: null,
            amountValid: true,
            symbol: '',
            tokenId: '',
            agreeToTermLength: false,
            agreeToTermsAndConditions: false,
            // UI usage
            balance: '0',
            balanceBN: '',
            ethusd: null,
            currentValueUSD: '',
            min: '0',
            max: '0',
            canMax: false,
            // Most recent
            nft: '',
            // All NFTs
            nfts: '',
            // Should we show them on the depost screen?
            nftsShown: false,
            // Validation
            minBN: null,
            maxBN: null,
            // When something goes wrong
            error: '',
            // After deposit
            transactionHash: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickAddToken = this.handleClickAddToken.bind(this);
        this.sourceChange = this.sourceChange.bind(this);
        // this.getBalance = this.getBalance.bind(this);
        this.allowanceNeeded = this.allowanceNeeded.bind(this);
        this.invest = this.invest.bind(this);
        this.increaseAllowance = this.increaseAllowance.bind(this);
        this.sourceDecimalAdd = this.sourceDecimalAdd.bind(this);
        this.sourceDecimalSub = this.sourceDecimalSub.bind(this);
        this.sourceDecimalSubString = this.sourceDecimalSubString.bind(this);
        this.ethusd = this.ethusd.bind(this);
        this.max = this.max.bind(this);
        this.accountsChanged = this.accountsChanged.bind(this);
        this.chainChanged = this.chainChanged.bind(this);
        this.loadContract = this.loadContract.bind(this);
        this.showNFTs = this.showNFTs.bind(this);

        this.ethusdTimer = null;
        // this.connectionCheck = null;
    }

    accountsChanged (accounts) {
        window.cfweb3accounts = accounts;
        window.sessionStorage.setItem('cfweb3accounts', JSON.stringify(accounts));
        console.log('Accounts changed', accounts);
        if (!accounts.length) {
            return this.setState({
                accounts,
                stage: 'error',
                error: 'Please connect your Ethereum account'
            });
        }
        else {
            return this.setState({
                accounts
            }, this.loadContract);
        }
    }

    /**
     *
     * @param string
     * @param options
     * @returns {string}
     */
    addCommas (string, options) {
        if (typeof options == 'undefined') {
            options = {maximumSignificantDigits: 10};
        }
        string = string.replace(',', '');
        return parseFloat(string).toLocaleString(undefined, options);
    }

    async allowanceNeeded () {
        const {
            sourceId,
            sources,
            balances,
            web3,
            amountBN,
            accounts,
            contract,
            amount
        } = this.state;

        let neededBN = web3.utils.toBN('0');

        if (
            // do not need allowance for ETH
            sourceId === '0'
            // do not need allowance if they have no ERC20
            || typeof balances[sourceId] === 'undefined'
            // or if they don't have enough to reach minimum
            || balances[sourceId] < sources[sourceId].minDeposit
            // This is not an ethereum-based source.
            || sources[sourceId].isExternal
        ) {
            // allowance not needed.
        }
        else {
            let allowances = Object.assign({}, this.state.allowances);
            let now = new Date();
            if (
                typeof allowances[sourceId] == 'undefined'
                || allowances[sourceId].updated < (now.getTime() - 2000)
            ) {
                let erc20 = new web3.eth.Contract([{
                    'type': 'function',
                    'name': 'allowance',
                    'constant': true,
                    'payable': false,
                    'inputs': [{
                        'name': 'owner',
                        'type': 'address'
                    }, {
                        'name': 'spender',
                        'type': 'address'
                    }],
                    'outputs': [{
                        'name': '',
                        'type': 'uint256',
                        'value': '0'
                    }]
                }], sources[sourceId].source);
                let currentAllowance = await erc20.methods.allowance(accounts[0], contract.options.address).call();
                allowances[sourceId] = {
                    amount: currentAllowance,
                    updated: now.getTime()
                };
                this.setState({allowances});
            }

            if (typeof allowances[sourceId].amount !== 'undefined') {
                let minBN = web3.utils.toBN(sources[sourceId].minDeposit);
                if (amountBN.lt(minBN)) {
                    // need at least the minimum deposit.
                    neededBN = minBN.sub(web3.utils.toBN(allowances[sourceId].amount));
                }
                else {
                    // need just amount specified
                    neededBN = amountBN.sub(web3.utils.toBN(allowances[sourceId].amount));
                }
            }

            if (neededBN.lt(web3.utils.toBN('0'))) {
                neededBN = web3.utils.toBN('0');
            }
        }
        let submitString = '';
        if (neededBN.gt(web3.utils.toBN('0'))) {
            submitString = 'Approve ' + amount + ' ' + sources[sourceId].symbol + ' for Deposit';
        }
        else if (sources[sourceId].isExternal) {
            submitString = 'Ready to Deposit ' + amount + ' ' + sources[sourceId].symbol;
        }
        else {
            submitString = 'Deposit ' + amount + ' ' + sources[sourceId].symbol;
        }

        this.setState({
            submit: submitString,
            allowanceNeeded: this.sourceDecimalSubString(sourceId, neededBN, 0),
            allowanceNeededBN: neededBN
        });
    }

    boot = async () => {
        const {web3, contract, accounts} = this.state;
        // Update entire contract paused condition.
        contract.methods.paused().call().then(async (result) => {
            if (!result) {

                // Get funds
                const pathname = window.location.pathname.toLowerCase().replace('deposit', '').replaceAll('/', '');
                let funds = Object.assign({}, this.state.funds);
                let fundId = this.state.fundId;
                for (let i = FUND_MIN; i <= FUND_MAX; i++) {
                    let fund = await contract.methods.getFund(i).call();
                    if (!fund.symbol.length && !fund.name.length) {
                        // Stop searching for funds when we hit an empty symbol
                        break;
                    }
                    funds[i] = fund;

                    // By default select the first active fund.
                    if (fund.active) {
                        if (
                            fundId === ''
                            || pathname === fund.symbol.toLowerCase()
                        ) {
                            // Either we've not yet made a selection, or this
                            // fund matches the URL path.
                            // Activate this fund for the user.
                            fundId = i + '';
                        }
                    }
                }

                // Get acceptable sources
                let sources = Object.assign({}, this.state.sources);
                let sourceId = this.state.sourceId;
                for (let i = SOURCE_MIN; i <= SOURCE_MAX; i++) {
                    let source = await contract.methods.getSource(i).call();
                    if (!source.symbol.length) {
                        // Stop searching for tokens when we hit an empty symbol
                        break;
                    }
                    sources[i] = source;

                    if (sourceId === '') {
                        if (source.active) {
                            sourceId = i + '';
                        }
                    }
                }

                // Get balances of the sources for the current user
                let balances = Object.assign({}, this.state.balances);
                for (const [id, source] of Object.entries(sources)) {
                    if (!source.active) {
                        continue;
                    }
                    if (id === '0') {
                        balances[0] = await web3.eth.getBalance(accounts[0]);
                    }
                    else if (source.isExternal) {
                        // Fake balance, since we cannot confirm yet
                        // for the sake of validation logic.
                        balances[id] = '5000000000000000000000000';
                    }
                    else {
                        let erc20 = new web3.eth.Contract([{
                            'type': 'function',
                            'name': 'balanceOf',
                            'constant': true,
                            'payable': false,
                            'inputs': [{'name': '', 'type': 'address'}],
                            'outputs': [{
                                'name': '',
                                'type': 'uint256',
                                'value': '0'
                            }]
                        }], source.source);
                        balances[id] = await erc20.methods.balanceOf(accounts[0]).call();
                    }
                }
                // Go ahead and render
                this.setState({
                    funds,
                    fundId,
                    fundName: (typeof funds[fundId] !== 'undefined' && typeof funds[fundId].name !== 'undefined') ? funds[fundId].name : 'Caiman Fund',
                    sources,
                    sourceId,
                    balances,
                    amountBN: web3.utils.toBN('0'),
                    stage: READY_STATE
                }, this.sourceChange);

                // Update the ETH - USD ratio every minute
                clearInterval(this.ethusdTimer);
                this.ethusdTimer = setInterval(this.ethusd, 60000);

                // Go ahead and load existing NFTs
                this.getNFTs();
            }
            else {
                this.setState({
                    stage: 'error',
                    error: 'Fund deposits are currently paused, please come back later.'
                });
            }
        });
    };

    chainChanged (input) {
        const {web3} = this.state;
        const networkId = typeof input == 'string' ? web3.utils.hexToNumber(input) : input;
        console.log('Chain changed', networkId);
        if (!this.getNetwork(networkId)) {
            return this.setState({
                networkId,
                stage: 'error',
                error: 'Please connect your wallet to the Ethereum Mainnet'
            });
        }
        else {
            return this.setState({
                networkId
            }, this.loadContract);
        }
    }

    componentDidMount = async () => {
        try {
            // Get network provider and web3 instance.
            const web3 = await getWalletConnect(this.accountsChanged, this.chainChanged);
            const networkId = await web3.eth.net.getId();
            this.setState({
                web3,
                networkId
            }, async () => {
                if (!this.state.networkId.length) {
                    // Handle situations where the initial hit is on the wrong
                    // network.
                    this.chainChanged(networkId);
                }
                if (!this.state.accounts || !this.state.accounts.length) {
                    web3.currentProvider.request({
                        method: 'eth_requestAccounts',
                    }).then(async () => {
                        this.accountsChanged(await web3.eth.getAccounts());
                    });
                }
            });
        }
        catch (error) {
            console.error(error);
            this.setState({
                stage: 'error',
                error: 'Please connect your MetaMask (or similar) wallet'
            });
        }
    };

    async ethusd () {
        return fetch(ETHPRICE_API)
            .then(response => response.json())
            .then(data => {
                if (typeof data.result.ethusd !== 'undefined') {
                    this.setState({ethusd: data.result.ethusd});
                }
            });
    }

    fundSelectItems () {
        const {funds} = this.state;
        let items = [];
        for (const [id, fund] of Object.entries(funds)) {
            items.push(<option key={id} value={id}
                               disabled={!fund.active}>{fund.name}</option>);
        }
        return items;
    }

    async getNFTs () {
        const {accounts, contract} = this.state;
        let nft = null;
        let nfts = [];
        if (contract && accounts.length > 0) {
            let uris = await contract.methods.tokenURIsByOwner(accounts[0]).call();
            if (!uris.length) {
                return ('');
            }
            let d = 0;
            for (let i = uris.length - 1; i >= 0; i--) {
                try {
                    let src = uris[i].replace('/token/', '/token/svg/');
                    let style = {'animationDelay': d + 's'};
                    nfts.push(
                        <div className="anim m-3"
                             key={uris[i]}
                             style={style}>
                            <div className="flip-in-hor-bottom" style={style}>
                                <div
                                    className="tilt-in-top-1 m-5 p-0"
                                    style={style}>
                                    <Tilt glareEnable={true} glareMaxOpacity={0.8} glareColor="#ffffff" glarePosition="bottom" glareBorderRadius="0px">
                                        <img src={src} alt="Your NFT" className="shadow-lg"/>
                                    </Tilt>
                                </div>
                            </div>
                        </div>
                    );
                    d += .8;
                    if (!nft) {
                        nft = nfts[0];
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
            if (nfts) {
                this.setState({
                    nft,
                    nfts
                });
            }
            return nfts;
        }
    }

    getNetwork (networkId) {
        return CaimanFund.networks[networkId] || false;
    }

    async handleChange (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'sourceId') {
            this.setState({
                [name]: value,
            }, this.sourceChange);
        }
        else if (name === 'amount') {
            await this.newAmount(value);
        }
        else if (name === 'fundId') {
            this.setState({
                [name]: value,
                fundName: this.state.funds[value].name
            });
        }
        else {
            this.setState({
                [name]: value,
            });
        }
    }

    async handleClickAddToken (event) {
        const {contract, web3} = this.state;
        try {
            web3.currentProvider.request({
                method: 'wallet_watchAsset',
                params: {
                    // NOTE: ERC721 is not yet supported, so this will just
                    // show up as a simple token currently.
                    type: 'ERC20',
                    options: {
                        address: contract._address,
                        symbol: TOKEN_SYMBOL,
                        decimals: TOKEN_DECIMALS,
                        image: TOKEN_IMAGE,
                    },
                },
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    async handleSubmit (event) {
        const {
            fundId,
            sourceId,
            funds,
            agreeToTermLength,
            agreeToTermsAndConditions,
            amount,
            amountBN,
            balanceBN,
            minBN,
            maxBN,
            allowanceNeededBN,
            web3,
            isExternal,
            externalSource
        } = this.state;

        event.preventDefault();
        event.stopPropagation();

        if (
            typeof event.target.checkValidity == 'function'
            && !event.target.checkValidity()
        ) {
            event.target.classList.add('was-validated');
            return;
        }

        // Fallback validators
        if (!amount.length) {
            await this.setState({amountValid: false});
            return;
            // alert('Must specify an amount of ' + symbol + ' to deposit');
        }
        if (!fundId.length) {
            return alert('Must select an active Fund to deposit to. The fund you have selected may have recently been paused for new deposits. Please contact us or select another fund.');
        }
        if (!sourceId.length) {
            return alert('Must select an active source to deposit from.');
        }
        if (typeof funds[this.state.fundId] == 'undefined' || !funds[fundId].active) {
            return alert('The fund you have selected is not currently accepting deposits. Please select another or contact us.');
        }
        if (!agreeToTermLength) {
            return alert('Must agree to the term length.');
        }
        if (!agreeToTermsAndConditions) {
            return alert('Must agree to the terms and conditions.');
        }
        if (balanceBN && amountBN.gt(balanceBN)) {
            this.setState({amountValid: false});
            return;
        }
        if (amountBN.lt(minBN)) {
            this.setState({amountValid: false});
            return;
            // return alert('Your position amount must be at least ' +
            // this.addCommas(min) + ' ' + symbol + '.');
        }
        if (amountBN.gt(maxBN)) {
            this.setState({amountValid: false});
            return;
            // return alert('Your position amount is currently limited to
            // ' + this.addCommas(max) + ' ' + symbol + '.');
        }
        if (isExternal && (externalSource.length < EXTERNAL_WALLET_MIN_LENGTH || externalSource > EXTERNAL_WALLET_MAX_LENGTH)) {
            return alert('Your wallet address appears incorrect.');
        }

        if (allowanceNeededBN.gt(web3.utils.toBN('0'))) {
            await this.increaseAllowance();
        }
        else {
            await this.invest();
        }
    }

    async increaseAllowance () {
        const {
            contract,
            accounts,
            web3,
            sources,
            sourceId,
            allowanceNeededBN
        } = this.state;

        this.setState({stage: 'waiting'});

        try {
            let erc20 = new web3.eth.Contract([{
                'type': 'function',
                'name': 'increaseAllowance',
                'constant': true,
                'payable': true,
                'inputs': [{
                    'name': 'spender',
                    'type': 'address'
                }, {
                    'name': 'addedValue',
                    'type': 'uint256'
                }],
                'outputs': [{
                    'name': '',
                    'type': 'bool',
                    'value': 'false'
                }]
            }], sources[sourceId].source);
            let result = await erc20.methods.increaseAllowance(contract.options.address, allowanceNeededBN.toString()).send({
                from: accounts[0]
            });
            if (
                typeof result.status !== 'undefined'
                && result.status
            ) {
                this.setState({
                    transactionHash: result.transactionHash,
                    stage: 'approve'
                }, this.allowanceNeeded);
                console.log(result);
            }
            else {
                this.setState({
                    stage: 'error',
                    error: 'Something went wrong during the transaction'
                });
                console.error(result);
            }
        }
        catch (e) {
            if (e.message.indexOf('denied transaction')) {
                this.setState({stage: 'ready'});
            }
            else {
                this.setState({
                    stage: 'error'
                });
            }
            console.error(e);
        }
    }

    async invest () {
        const {
            fundId,
            amountBN,
            contract,
            accounts,
            sourceId,
            isExternal,
            externalSource
        } = this.state;

        let destinationStage = 'complete';
        if (isExternal) {
            await this.setState({stage: 'processingExternal'});
            destinationStage = 'waitingExternal';
        }
        else {
            await this.setState({stage: 'processing'});
        }

        try {
            let result = null;
            if ('0' === sourceId) {
                console.log('depositing ether');
                // Investing Ether
                result = await contract.methods.investEther(fundId).send({
                    from: accounts[0],
                    value: amountBN.toString()
                });
            }
            else if (isExternal) {
                console.log('depositing external asset');
                result = await contract.methods.investExternal(
                    fundId,
                    sourceId,
                    amountBN.toString(),
                    externalSource
                ).send({
                    from: accounts[0]
                });
            }
            else {
                console.log('depositing tokens');
                // Investing ERC20 tokens (assume allowance is met by this
                // point)
                result = await contract.methods.investTokens(
                    fundId,
                    sourceId,
                    amountBN.toString()
                ).send({
                    from: accounts[0]
                });
            }

            if (
                typeof result.status !== 'undefined'
                && result.status
            ) {
                // Flush this to re-fetch latest tokens.
                window.cffunds = {};
                window.cftokens = [];
                window.sessionStorage.setItem('cftokens', []);
                await this.getNFTs();
                this.setState({
                    transactionHash: result.transactionHash,
                    stage: destinationStage
                });
                console.log(result);
            }
            else {
                this.setState({
                    stage: 'error',
                    error: 'Something went wrong during the transaction'
                });
                console.error(result);
            }
        }
        catch (e) {
            if (e.message.indexOf('denied transaction')) {
                this.setState({stage: 'ready'});
            }
            else {
                this.setState({
                    stage: 'error'
                });
            }
            console.error(e);
        }
    }

    loadContract () {
        const {networkId, accounts, web3} = this.state;
        const network = this.getNetwork(networkId);
        if (web3 && network && accounts.length > 0) {
            this.setState({
                contract: new web3.eth.Contract(
                    CaimanFund.abi,
                    network.address,
                ),
            }, this.boot);
        }
    }

    max () {
        const {maxBN} = this.state;
        this.newAmount(maxBN);
    }

    newAmount (amount) {
        const {
            ethusd,
            web3,
            minBN,
            maxBN,
            sourceId,
            sources,
            balanceBN
        } = this.state;
        if (typeof amount === 'object') {
            amount = amount.toString();
        }
        amount = amount
            .replace(/^0*/, '') // Preceding zeros
            .replace(/\..*/, '') // Following decimal points
            .replace(/[\D,]/g, ''); // Non numeric/comma characters
        if (amount === '') {
            amount = '0';
        }
        let amountBN = this.sourceDecimalAdd(sourceId, amount.replace(/,/g, ''));
        if (amountBN.gt(maxBN)) {
            amountBN = maxBN;
        }

        let currentValueUSD = '';
        if (sourceId === '0' && ethusd) {
            let amountEth = this.sourceDecimalSub(sourceId, amountBN);
            if (amountEth.gt(web3.utils.toBN('0'))) {
                let cents = amountEth.mul(web3.utils.toBN(Math.round(ethusd * 100))).toString();
                let dollars = cents.slice(0, -2) + '.' + cents.slice(-2);
                dollars = dollars.replace('.00', '');
                currentValueUSD = '~ ' + this.addCommas(dollars, {
                    style: 'currency',
                    currency: 'USD'
                });
            }
        }
        if (amount === '0') {
            amount = '';
        }
        else {
            amount = this.sourceDecimalSubString(sourceId, amountBN, 0);
        }
        this.setState({
            amount,
            amountBN,
            amountValid: !amount.length || (amountBN.gte(minBN) && amountBN.lte(maxBN) && amountBN.lte(balanceBN)),
            currentValueUSD,
            canMax: sourceId !== '0' && amountBN.lt(maxBN) && !sources[sourceId].isExternal
        }, this.allowanceNeeded);
    }

    render () {
        document.body.classList.add('deposit');
        const $colClass = 'mt-4 col-lg-8 g-lg-0 p-lg-5 mt-lg-0';
        return (
            <div>
                <Container fluid className="g-0 d-block d-lg-none">
                    <div
                        className="slogan-horizontal bg-gradient1 p-4 text-center">
                        <h1 className="font-family-serif fw-light mt-4">
                            Black-tie, bow-tie,<br/>
                            blockchain focused<br/>
                            fund investing.
                        </h1>
                        <img className="m-4" id="invest-logo"
                             alt="Caiman Fund"
                             src="/img/CF-Logo-vertical.svg"
                        />
                    </div>
                </Container>
                <div
                    className="mt-5 mb-5 p-5 d-none d-lg-block">
                </div>
                <div className="container-xl">
                    <Row
                        className="m-lg-5 bg-light">
                        <Col
                            className="d-none d-lg-block col-lg-4 g-lg-0 mt-lg-0">
                            <div
                                className="slogan-vertical bg-gradient1 p-5 text-center">
                                <div className="mt-4 pt-4">
                                    <h2 className="font-family-serif fw-lighter mt-5 mb-5">
                                        Black-tie,<br/>
                                        bow-tie,<br/>
                                        blockchain<br/>
                                        focused fund<br/>
                                        investing.
                                    </h2>
                                    <img
                                        className={this.state.stage === 'connecting' ? 'o-0 mt-5 pt-5' : 'o-100 mt-5 pt-5'}
                                        id="invest-logo"
                                        alt="Caiman Fund"
                                        src="/img/CF-Logo-vertical.svg"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col
                            className={this.state.stage === 'connecting' ? $colClass : 'd-none'}>
                            <div
                                className="d-flex align-items-center w-100 h-100 mb-5 text-center">
                                <div className="m-auto w-50">
                                    <img className="loading"
                                         alt="Caiman Fund"
                                         src="/img/loading-continuous.svg"
                                    />
                                    <h3 className="ellipses">Connecting</h3>
                                </div>
                            </div>
                        </Col>
                        <Col
                            className={this.state.stage === 'ready' ? $colClass : 'd-none'}>
                            <div className="mb-4">
                                <Link to='/portfolio'
                                      className="float-end cf-invest-close-button"
                                      alt="cancel"
                                >
                                    <div className="cf-cancel">&nbsp;</div>
                                </Link>
                            </div>
                            <form onSubmit={this.handleSubmit}
                                  className="needs-validation mb-3"
                                  noValidate>
                                <h3>Your Position</h3>
                                <div className="mb-3">
                                    <label htmlFor="fundId"
                                           className="form-label">FUND TYPE</label>
                                    <select id="fundId" name="fundId"
                                            required
                                            className="form-select"
                                            autoComplete="off"
                                            aria-label="Select Fund"
                                            value={this.state.fundId}
                                            onChange={this.handleChange}>
                                        {this.fundSelectItems()}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="amount"
                                           className="form-label">AMOUNT</label>
                                    <div className="input-group mb-3">
                                        <input id="amount" name="amount"
                                               className={this.state.amountValid ? 'form-control' : 'form-control is-invalid'}
                                               required
                                               inputMode="numeric"
                                               maxLength="18"
                                               pattern={this.state.amountPattern}
                                               autoComplete="off"
                                               aria-describedby="amountAddon"
                                               placeholder={this.state.placeholder}
                                               value={this.state.amount}
                                               onChange={this.handleChange}
                                        />
                                        <select id="sourceId"
                                                name="sourceId"
                                                required
                                                className="dropdown-toggle form-select"
                                                autoComplete="off"
                                                aria-label="Select Funding Source"
                                                value={this.state.sourceId}
                                                onChange={this.handleChange}
                                        >
                                            {this.sourceSelectItems()}
                                        </select>
                                        <div
                                            className="invalid-feedback float-start">
                                            Investment requires {this.state.symbol} {this.state.placeholder}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={!this.state.isExternal && this.state.balance !== '0' ? 'mb-5' : 'd-none'}>
                                    <small className="float-end">
                                        Wallet Balance: <span
                                        onClick={this.max}
                                        className={this.state.canMax ? 'text-decoration-underline' : ''}>{this.state.balance}</span> {this.state.symbol}
                                    </small>
                                </div>

                                <div
                                    className={this.state.isExternal ? 'mb-3' : 'd-none'}>
                                    <label htmlFor="externalSource"
                                           className="form-label">YOUR {this.state.symbol} ADDRESS</label>
                                    <input id="externalSource"
                                           name="externalSource"
                                           className="form-control"
                                           autoComplete="off"
                                           value={this.state.externalSource}
                                           aria-describedby="externalSource"
                                           pattern={this.state.externalPattern}
                                           required={this.state.isExternal}
                                           title="Make sure this matches your wallet address that you will be sending from"
                                           onChange={this.handleChange}
                                    />
                                    <div
                                        className="invalid-feedback">
                                        This does not appear to be a valid {this.state.symbol} address.
                                    </div>
                                    <small>
                                        We need your {this.state.symbol} address to correlate your upcoming deposit.
                                    </small>
                                </div>

                                <div className="form-check mb-3">
                                    <input id="agreeToTermLength"
                                           name="agreeToTermLength"
                                           className="form-check-input"
                                           required
                                           type="checkbox"
                                           autoComplete="off"
                                           checked={this.state.agreeToTermLength}
                                           onChange={this.handleChange}
                                    />
                                    <label
                                        className="form-check-label text-small"
                                        htmlFor="agreeToTermLength">
                                        I understand that my investment will be locked for <strong>9 months</strong>.
                                    </label>
                                    <div className="invalid-feedback">
                                        You must agree before depositing.
                                    </div>
                                </div>

                                <div className="form-check mb-3">
                                    <input id="agreeToTermsAndConditions"
                                           name="agreeToTermsAndConditions"
                                           className="form-check-input"
                                           required
                                           type="checkbox"
                                           autoComplete="off"
                                           checked={this.state.agreeToTermsAndConditions}
                                           onChange={this.handleChange}
                                    />
                                    <label className="form-check-label"
                                           htmlFor="agreeToTermsAndConditions">
                                        I have read and agree with the <a
                                        href="https://caiman.fund/terms"
                                        target="_blank"
                                        rel="noreferrer">terms and conditions</a>.
                                    </label>
                                    <div className="invalid-feedback">
                                        You must agree before depositing.
                                    </div>
                                </div>

                                <div
                                    className={this.state.allowanceNeeded !== '0' && this.state.amountBN.gt(this.state.minBN) ? 'mb-3' : 'd-none'}>
                                    <small>
                                        You will approve an allowance increase of {this.state.allowanceNeeded} {this.state.symbol} to continue
                                    </small>
                                </div>

                                <div className="mb-3 mt-5">
                                    <button type="submit" value="submit"
                                            className="btn btn-outline-primary mb-2">{this.state.submit}
                                    </button>
                                    <div
                                        className={this.state.currentValueUSD.length ? '' : 'd-none'}>
                                        <small className="text-muted m-4">
                                            {this.state.currentValueUSD}
                                        </small>
                                    </div>
                                </div>
                                <div
                                    className={this.state.nfts ? 'mt-5' : 'd-none'}>
                                    <span className={this.state.nftsShown ? 'd-none' : 'float-end btn-sm a'}
                                       style={{'cursor': 'pointer'}}
                                       onClick={this.showNFTs}>View my NFTs</span>
                                    <div className={this.state.nftsShown ? '' : 'd-none'}>
                                        <hr/>
                                        <h3>Your Existing NFTs</h3>
                                        {this.state.nftsShown ? this.state.nfts : ''}
                                    </div>
                                </div>
                            </form>

                        </Col>
                        <Col
                            className={this.state.stage === 'processingExternal' ? $colClass : 'd-none'}>
                            <div
                                className="d-flex align-items-center w-100 h-50 mb-5 text-center">
                                <div className="m-auto w-50">
                                    <img className="loading"
                                         alt="Caiman Fund"
                                         src="/img/loading-continuous.svg"
                                    />
                                    <h3 className="ellipses">Processing</h3>
                                </div>
                            </div>
                            <p className="text-center pt-1">
                                Approving your deposit of {this.state.amount} {this.state.symbol} into {this.state.fundName}.<br/>
                                This will require a small gas fee to mint your NFT on the Ethereum blockchain to signify your upcoming deposit.
                            </p>
                        </Col>
                        <Col
                            className={this.state.stage === 'waitingExternal' ? $colClass : 'd-none'}>
                            <h3>Ready for {this.state.symbol}</h3>

                            <p>
                                {this.transactionHashLink()} &nbsp;
                                We are now ready to receive your deposit of {this.state.amount} {this.state.symbol} into {this.state.fundName}.<br/>
                            </p>
                            <div className="mb-3">
                                <label className="form-label strong"
                                       htmlFor="externalAddress">
                                    Please transfer your {this.state.symbol} to:
                                </label>
                                <div className="input-group mb-3">
                                    <input id="externalAddress"
                                           name="externalAddress"
                                           className="form-control"
                                           autoComplete="off"
                                           aria-describedby="externalAddress"
                                           value={this.state.externalDestination}
                                           readOnly
                                    />
                                    <button
                                        onClick={() => {navigator.clipboard.writeText(this.state.externalDestination);}}
                                        className="btn btn-primary"
                                        type="button">copy
                                    </button>
                                </div>
                            </div>
                            <p>Once the {this.state.amount} {this.state.symbol} has been received at the address above, we will confirm deposit.</p>

                            <p>In the meantime you can add the token to your wallet viewer.</p>

                            <button onClick={this.handleClickAddToken}
                                    className="btn btn-outline-primary mw-100">
                                Add token to wallet viewer
                            </button>
                        </Col>
                        <Col
                            className={this.state.stage === 'processing' ? $colClass : 'd-none'}>
                            <div
                                className="d-flex align-items-center w-100 h-50 mb-5 text-center">
                                <div className="m-auto w-50">
                                    <img className="loading"
                                         alt="Caiman Fund"
                                         src="/img/loading-continuous.svg"
                                    />
                                    <h3 className="ellipses">Processing</h3>
                                </div>
                            </div>
                            <p className="text-center pt-5">
                                Investing {this.state.amount} {this.state.symbol} into {this.state.fundName}.
                            </p>
                        </Col>
                        <Col
                            className={this.state.stage === 'waiting' ? $colClass : 'd-none'}>
                            <div
                                className="d-flex align-items-center w-100 h-50 mb-5 text-center">
                                <div className="m-auto w-50">
                                    <img className="loading"
                                         alt="Caiman Fund"
                                         src="/img/loading-continuous.svg"
                                    />
                                    <h3 className="ellipses">
                                        Waiting
                                    </h3>
                                </div>
                            </div>
                            <p className="text-center pt-1">
                                After you approve the allowance increase, it can take a few seconds up to a few minutes for the transaction to be confirmed on the blockchain. In a few moments you will be able to approve your position on the next step.
                            </p>
                        </Col>
                        <Col
                            className={this.state.stage === 'approve' ? $colClass : 'd-none'}>
                            <h3>Almost There!</h3>
                            <p>{this.state.symbol} allowance has been approved. You may now deposit your {this.state.amount} {this.state.symbol}.</p>
                            <form onSubmit={this.handleSubmit}
                                  className="mb-3">
                                <button type="submit" value="submit"
                                        className="btn btn-outline-primary">{this.state.submit}
                                </button>
                            </form>
                        </Col>
                        <Col
                            className={this.state.stage === 'error' ? $colClass : 'd-none'}>
                            <h3>Problem</h3>

                            <p>{this.state.error}</p>

                            <button onClick={this.retry}
                                    className="btn btn-outline-primary mb-5 mt-5">
                                Try Again
                            </button>
                        </Col>
                        <Col
                            className={this.state.stage === 'complete' ? $colClass : 'd-none'}>
                            <h3>
                                Congratulations, your position in {this.state.fundName} is now complete.
                            </h3>

                            <p>{this.transactionHashLink()}</p>

                            {this.state.nft}

                            <div className="mb-3 mt-3">
                                <label className="form-label strong"
                                       htmlFor="contractAddress">
                                    CONTRACT ADDRESS
                                </label>
                                <div className="input-group mb-3">
                                    <input id="contractAddress"
                                           name="contractAddress"
                                           className="form-control border-0"
                                           autoComplete="off"
                                           aria-describedby="externalAddress"
                                           value={this.state.contract ? this.state.contract._address : ''}
                                           readOnly
                                    />
                                </div>
                            </div>

                            <button onClick={this.handleClickAddToken}
                                    className="btn btn-outline-primary mb-3 mw-100">
                                Add NFT to your wallet
                            </button>

                            <span className="mt-5">
                                <p className="fs-5 fw-bold mb-3 mt-5 pt-2">What&apos;s Next:</p>
                                <ul>
                                    <li>
                                        Check in on your fund positions at any time directly from your Portfolio page. Remember not all assets may be shown at all times as some positions may not yet be reflected in the reporting.
                                    </li>
                                    <li>
                                        You are now an official member of {this.state.fundName}, join the Telegram group to stay in touch with other members, receive early access to fund growth reports and learn about new fund opportunities first.
                                    </li>
                                    <li>
                                        View your fund's growth within 48 hours directly from <Link to='/portfolio'>your portfolio</Link>.
                                    </li>
                                </ul>
                            </span>

                            <Link to='/'>return home</Link>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    retry () {
        // Reloading the entire page ensures we're reconnecting to the
        // wallet.
        window.location.reload();
        // this.setState({stage: 'ready'});
    }

    showNFTs () {
        this.setState({'nftsShown': true});
    }

    sourceChange () {
        const {
            sources,
            sourceId,
            amount,
            amountBN,
            balances,
            web3
        } = this.state;
        if (!sourceId.length) {
            return;
        }

        let minBN = web3.utils.toBN(sources[sourceId].minDeposit);
        let maxBN = web3.utils.toBN(sources[sourceId].maxDeposit);

        let balanceBN = web3.utils.toBN(balances[sourceId]);
        if (maxBN.gt(balanceBN)) {
            if (balanceBN.gt(minBN)) {
                maxBN = balanceBN;
            }
            else {
                maxBN = minBN;
            }
        }

        // Convert to strings for state
        let min = this.sourceDecimalSubString(sourceId, minBN, 0);
        let max = this.sourceDecimalSubString(sourceId, maxBN, 0);

        // Empty amount if changes are made and it is now out of bounds.
        let canMax = sourceId !== '0' && amountBN.lt(maxBN) && !sources[sourceId].isExternal;
        if (amount.length) {
            if (amountBN.lt(minBN) || amountBN.gt(maxBN)) {
                this.newAmount('');
                canMax = sourceId !== '0' && !sources[sourceId].isExternal;
            }
        }
        let externalPattern = '.*';
        let externalDestination = '';
        if (sources[sourceId].isExternal) {
            let sym = sources[sourceId].symbol.toLowerCase();
            if (typeof EXTERNAL_WALLET_PATTERNS[sym] != 'undefined') {
                externalPattern = EXTERNAL_WALLET_PATTERNS[sym];
            }
            externalDestination = sources[sourceId].externalDestination;
        }

        this.setState({
            min,
            minBN,
            max,
            maxBN,
            placeholder: min === max ? min : (min + ' ~ ' + max),
            // This precision level is what MetaMask uses
            balance: this.sourceDecimalSubString(sourceId, balanceBN, 4),
            balanceBN,
            symbol: sources[sourceId].symbol,
            canMax: canMax,
            isExternal: sources[sourceId].isExternal,
            externalPattern,
            externalDestination
        }, this.allowanceNeeded);

        if (sourceId === '0') {
            this.ethusd();
        }
    }

    sourceDecimalAdd (sourceId, val) {
        const {web3, sources} = this.state;
        let bnVal = web3.utils.toBN(val);
        let bnDecimals = web3.utils.toBN(sources[sourceId].decimals);

        return bnVal.mul(web3.utils.toBN('10').pow(bnDecimals));
    }

    sourceDecimalSub (sourceId, val) {
        const {web3, sources} = this.state;
        if (typeof val === 'undefined') {
            val = '0';
        }
        let valBN = (typeof val !== 'object' ? web3.utils.toBN(val) : val);
        let decimalsBN = web3.utils.toBN(sources[sourceId].decimals);

        return valBN.div(web3.utils.toBN('10').pow(decimalsBN));
    }

    sourceDecimalSubString (sourceId, val, precision) {
        const {web3, sources} = this.state;
        if (typeof val === 'undefined') {
            val = '0';
        }

        let valBN = (typeof val !== 'object' ? web3.utils.toBN(val) : val);
        let decimalsBN = web3.utils.toBN(parseInt(sources[sourceId].decimals) - precision);
        let string = valBN.divRound(web3.utils.toBN('10').pow(decimalsBN)).toString();
        while (string.length <= precision) {
            string = '0' + string;
        }
        if (precision > 0) {
            string = string.substring(0, string.length - precision) + '.' + string.substring(string.length - precision);
        }
        return this.addCommas(string);
    }

    sourceSelectItems () {
        const {balances, sources, web3} = this.state;
        let items = [];
        for (const [id, source] of Object.entries(sources)) {
            let disabled = true;
            if (source.active) {
                if (source.isExternal) {
                    disabled = source.externalDestination.length < EXTERNAL_WALLET_MIN_LENGTH;
                }
                else {
                    disabled = web3.utils.toBN(balances[id]).lt(web3.utils.toBN(source.minDeposit));
                }
            }
            items.push(<option key={id} value={id}
                               disabled={disabled}>{source.symbol}</option>);
        }
        return items;
    }

    transactionHashLink () {
        let {transactionHash} = this.state;
        if (!transactionHash.length) {
            return;
        }
        let link = TRANSACTION_LINKS + transactionHash;
        return (
            <span>Your transaction confirmation can be viewed <a href={link}
                                                                 target="_blank"
                                                                 className="transaction-link"
                                                                 rel="noreferrer">here</a>.
            </span>
        );
    }
}

export default DepositForm;