import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import Hero from '../components/Hero';
import FooterLarge from '../components/FooterLarge';

const Home = () => {
    return (
        <div>
            <Hero/>
            <section className="bg-dark bg-dark-w-green text-light">
                <Container>
                    <h4 className="text-center mt-5 pt-5">The Caiman Fund Strategy</h4>
                    <Row>
                        <div className="cf-diamond col-lg-4">
                            Automated algorithmic trading strategies
                        </div>
                        <div className="cf-diamond col-lg-4">
                            Long-term swing trades
                        </div>
                        <div className="cf-diamond col-lg-4">
                            Decentralized financial loan brokering
                        </div>
                        <div className="cf-diamond col-lg-4">
                            Major market indices, stocks, bonds, mutual funds and precious metals
                        </div>
                        <div className="cf-diamond col-lg-4">
                            Passive exposure to Bitcoin and other blue-chip crypto assets
                        </div>
                        <div className="cf-diamond col-lg-4">
                            Actively managed exposure to early-stage blockchain protocols
                        </div>
                    </Row>
                </Container>
            </section>
            <Container>
                <Row className="my-5">
                    <Col className="col-12 col-md-6">
                        <h5>
                            The Caiman Fund utilizes a <strong>sophisticated multi-strategy approach</strong>&nbsp;
                            focused primarily on decentralized finance and <strong>a quantitative 24/7 automated algorithmic trading engine.</strong>
                        </h5>
                    </Col>
                    <Col className="col-12 col-md-3">
                        <p>
                            <span className="cf-icon-03 mb-3">&nbsp;</span>
                            <strong>Gain fast, safe exposure</strong> to Bitcoin and other blue chip assets while maximizing and compounding daily interest yields.
                        </p>
                    </Col>
                    <Col className="col-12 col-md-3">
                        <p>
                            <span className="cf-icon-04 mb-3">&nbsp;</span>
                            <strong>Diversify across a wide range of liquidity timelines</strong> to provide a stable vehicle for investment in the blockchain ecosystem of decentralized apps, tokenomics and other digital assets.
                        </p>
                    </Col>
                </Row>
            </Container>
            <FooterLarge/>
        </div>
    );
};

export default Home;
