import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import Header from '../components/Header';
import Link from '../components/Link';
import FooterSubscribe from '../components/FooterSubscribe';

const Contact = () => {
    return (
        <div>
            <Header/>
            <section className="container-fluid bg-gradient-offset2">
                <Container>
                    <Row>
                        <Col className="col-xl-7 col-sm-8">
                            <h1 className="mt-3 mb-2">
                                How can we help?
                            </h1>
                            <p className="mb-4 mt-3">
                                Please let us know if you have any <strong>questions</strong>, <strong>suggestions</strong>, or need <strong>assistance</strong>.<br/>
                                We are happy to hear from you!
                            </p>
                            <div id="mc_embed_signup" className="me-5">
                                <form
                                    action="https://fund.us5.list-manage.com/subscribe/post?u=fbd94aca31dcfb0280a15850e&amp;id=b736c68d42"
                                    method="post"
                                    id="mc-embedded-subscribe-form"
                                    name="mc-embedded-subscribe-form"
                                    className="validate mb-5" target="_blank">
                                    <div id="mc_embed_signup_scroll">
                                        <div className="form-group mb-4 pb-2">
                                            <label
                                                htmlFor="mce-EMAIL">Email Address </label>
                                            <input type="email"
                                                   required={true}
                                                   name="EMAIL"
                                                   className="required email form-control"
                                                   placeholder="youremail@domain.com"
                                                   id="mce-EMAIL"/>
                                        </div>
                                        <div className="form-group mb-4 pb-2">
                                            <label
                                                htmlFor="mce-TELEGRAM">Optional Telegram Username </label>
                                            <input type="text"
                                                   name="TELEGRAM"
                                                   className="form-control"
                                                   placeholder="@username"
                                                   id="mce-TELEGRAM"/>
                                        </div>
                                        <div className="form-group mb-4 pb-2">
                                            <label
                                                htmlFor="mce-SUBJECT">Subject</label>
                                            <select name="SUBJECT"
                                                    className=" form-control"
                                                    required={true}
                                                    id="mce-SUBJECT">
                                                <option
                                                    value="Question about Caiman Fund">
                                                    General Question
                                                </option>
                                                <option
                                                    value="Support Request">
                                                    Support Request
                                                </option>
                                                <option
                                                    value="Feature Suggestion">
                                                    Feature Suggestion
                                                </option>
                                                <option value="Other">
                                                    Other
                                                </option>

                                            </select>
                                        </div>
                                        <div className="form-group mb-4 pb-2">
                                            <label
                                                htmlFor="mce-MESSAGE">Your Message </label>
                                            <textarea
                                                required={true}
                                                name="MESSAGE"
                                                className="required form-control"
                                                placeholder="Send us a message and we'll start a conversation!"
                                                id="mce-MESSAGE"/>
                                        </div>
                                        <div id="mce-responses"
                                             className="clear mb-4">
                                            <div className="response"
                                                 id="mce-error-response"
                                                 style={{display: 'none'}}>&nbsp;</div>
                                            <div className="response"
                                                 id="mce-success-response"
                                                 style={{display: 'none'}}>&nbsp;</div>
                                        </div>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: '-5000px'
                                            }}
                                            aria-hidden="true">
                                            <input
                                                type="text"
                                                name="b_fbd94aca31dcfb0280a15850e_b736c68d42"
                                                tabIndex="-1"/>&nbsp;
                                        </div>
                                        <div className="clear">
                                            <input type="submit"
                                                   value="Submit Message"
                                                   name="subscribe"
                                                   id="mc-embedded-subscribe"
                                                   className="button btn btn-outline-primary"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Col>
                        <Col className="col-xl-3 col-sm-4 ps-xl-5">
                            <p className="sub-head mt-5 mb-5">
                                Our fund provides participants with <strong>calculated risk exposure</strong> to a full range of blockchain-based digital assets and alternative yield generating strategies and protocols.
                            </p>
                            <Link
                                className="btn btn-outline-primary mb-5 btn-sm btn-white"
                                to="/invest">
                                Launch the app
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>
            <FooterSubscribe/>
        </div>
    );
};

export default Contact;