import { Col, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import PortfolioInvestmentDemo from './PortfolioInvestmentDemo';
import Funds from '../Funds';

class Team extends Component {

    render () {
        let fundId = null;
        let p = window.location.pathname.split('/');
        if (p.length >= 3) {
            let path = p[p.length - 1].toLowerCase();
            for (const f in Funds) {
                if (Funds[f].symbol.toLowerCase() === path) {
                    fundId = f;
                    break;
                }
            }
        }

        let className = 'container-fluid bg-dark text-light bg-dark-w-green pad-sm';
        let demo = null;
        if (fundId) {
            demo = (
                <PortfolioInvestmentDemo fundId={fundId}/>
            );
            className += ' cf-demo-window-container';
        }
        return (
            <section className={className}>
                <Row>
                    <h4 className="text-center mt-5 pt-2">The Team</h4>
                    <Col className="col-12 col-md-4 mt-4 mb-5">
                        <p className="mt-5">
                            <span className="cf-icon-fund-01 mb-5">&nbsp;</span>
                            <strong>Our Background</strong> contains decades of experience in technology, automation, financial systems, digital assets, market analysis, market signaling, trading platforms and enterprise software.
                        </p>
                    </Col>
                    <Col className="col-12 col-md-4 mt-4 mb-5">
                        <p className="mt-5">
                            <span className="cf-icon-fund-02 mb-5">&nbsp;</span>
                            <strong>Our Approach</strong> consists of a unique blend of traditional market signals, strategies and deployment vehicles merged with a modern, technical approach to digital asset management and growth.
                        </p>
                    </Col>
                    <Col className="col-12 col-md-4 mt-4 mb-5">
                        <p className="mt-5">
                            <span className="cf-icon-fund-03 mb-5">&nbsp;</span>
                            <strong>Our Strategies</strong> build on the backgrounds and experience of not only the Caiman Fund managing partners, but also of our technical analysts, market researchers, and the entirety of the traditional and digital stock market historical performances as analyzed by our proprietary machine-learned algorithms.
                        </p>
                    </Col>
                </Row>
                {demo}
            </section>
        );
    }
}

export default Team;
