import Header from '../components/Header';
// import Wallet from '../components/Wallet';
import Footer from '../components/Footer';
import React, { Component } from 'react';
import PortfolioInvestmentExpanded
    from '../components/PortfolioInvestmentExpanded';
import { Container } from 'react-bootstrap';
import { DemoToken, tokensToString, UrisToTokens } from '../Tokens';

class Test extends Component {
    constructor (props) {
        super(props);

        this.state = {
            tokens: [DemoToken(1, '1')]
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (event) {
        let tokenStrings = document.getElementById('token').value.split(',');
        const tokens = UrisToTokens(tokenStrings);

        this.setState({tokens});
    }

    render () {
        const {tokens} = this.state;
        return (
            <>
                <Header/>
                <Container>
                    <div className="mb-3 col-6">
                        <label className="form-label strong"
                               htmlFor="token">
                            Test token:<br/>
                            Format: tokenId.depositTime.fundId.sourceId.amount.status
                        </label>
                        <div className="input-group mb-3">
                            <input id="token"
                                   name="token"
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   aria-describedby="token"
                                   required
                                   defaultValue={tokensToString(tokens)}
                            />
                            <button
                                onClick={this.handleChange}
                                className="btn btn-primary"
                                type="button">Test
                            </button>
                        </div>
                    </div>
                    <PortfolioInvestmentExpanded tokens={tokens}/>
                </Container>
                {/*<Wallet nfts={true}/>*/}
                <Footer bright={true}/>
            </>
        );
    }
}

export default Test;