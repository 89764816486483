import { Nav } from 'react-bootstrap';
import React, { Component } from 'react';
import Routes from '../Routes';
import { withRouter } from 'react-router-dom';
import Link from './Link';
import 'bootstrap/js/dist/collapse';
import Wallet from './Wallet';

class Navigation extends Component {
    collapse () {
        let elem = document.getElementById('navbar-collapser');
        let nav = document.getElementById('nav');
        if (elem.className.indexOf('collapse') !== -1) {
            nav.classList.remove('navbar-expanded');
        }
        else {
            nav.classList.add('navbar-expanded');
        }
    }

    render () {
        let items = [];
        let wallet;
        let classes = 'navbar navbar-expand-lg navbar-light mt-5 mb-5';
        let brandClasses = 'navbar-brand';
        let buttonClasses = 'navbar-toggler float-end';
        let navbarClasses = 'collapse navbar-collapse';
        let ulClasses = 'navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll';
        let logoFile = '/img/CF-Logo.svg';
        let logoId = 'horizontal-logo';
        let postLogo = '';
        let navItemClasses = 'nav-item';
        if (this.props.isFooter) {
            // classes = 'navbar navbar-dark navbar-expand-lg';
            // brandClasses = 'navbar-brand pt-4';
            // buttonClasses = 'd-none';
            // navbarClasses = 'navbar';
            // ulClasses = 'navbar-nav my-2 my-lg-0 navbar-nav-scroll';
            classes = 'navbar-dark col-md-12';
            brandClasses = 'navbar-brand pt-4 col-md-3';
            buttonClasses = 'd-none';
            navbarClasses = 'col-lg-6 pt-5 pb-4';
            navItemClasses = 'nav-item col-lg-4';
            ulClasses = 'my-2 my-lg-0';
            logoId = 'horizontal-logo-footer';
            logoFile = '/img/CF-Wordmark-footer.svg';
            postLogo = (
                <div className="nav-item copyright" key="copyright">
                    &copy; Caiman Fund {new Date().getFullYear()}
                </div>
            );
        }
        if (this.props.isPortfolio) {
            classes += ' fixed-top cf-portfolio-nav';
            brandClasses += ' bg-gradient1';
            logoId = 'brandmark-logo';
            logoFile = '/img/Caiman-Brandmark-LB.svg';
        }

        for (let [route, obj] of Object.entries(Routes)) {
            let className = 'nav-link';
            if (obj.exact) {
                if (this.props.location.pathname === route) {
                    className += ' active';
                    document.body.classList.add(obj.bodyClass);
                    document.title = obj.title;
                }
                else {
                    document.body.classList.remove(obj.bodyClass);
                }
            }
            else {
                route = route.replace('*', '')
                    .replace(/\/$/, '');
                if (this.props.location.pathname.indexOf(route) === 0) {
                    className += ' active';
                    document.body.classList.add(obj.bodyClass);
                    document.title = obj.title;
                }
                else {
                    document.body.classList.remove(obj.bodyClass);
                }
            }
            if (
                (obj.header && this.props.isHeader && !this.props.isPortfolio)
                || (obj.footer && this.props.isFooter)
                || (obj.portfolio && this.props.isPortfolio)
            ) {
                items.push(
                    <li className={navItemClasses} key={route}>
                        <Link to={route} className={className}>{obj.name}</Link>
                    </li>
                );
            }
        }
        if (this.props.wallet) {
            wallet =
                <li className="d-flex" id="wallet-container" key="wallet">
                    <Wallet modal={this.props.isPortfolio || false}/>
                </li>
            ;
        }

        return (
            <Nav
                id="nav"
                className={classes}>
                <Link className={brandClasses} to='/'>
                    <img src={logoFile}
                         id={logoId}
                         className="pt-4 pl-4 pb-4"
                         alt="Caiman Fund"/>
                    {postLogo}
                </Link>
                <button className={buttonClasses}
                        type="button"
                        data-bs-toggle="collapse"
                        id="navbar-collapser"
                        data-bs-target="#navbarScroll"
                        aria-controls="navbarScroll" aria-expanded="false"
                        onClick={this.collapse}
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">&nbsp;</span>
                </button>
                <div className={navbarClasses} id="navbarScroll">
                    <ul className={ulClasses}>
                        {items}
                    </ul>
                    {wallet}
                </div>
            </Nav>
        );
    }
}

export default withRouter(Navigation);





