import React from 'react';
import Header from '../components/Header';
import FundList from '../components/FundList';
import { Col, Container, Row } from 'react-bootstrap';
import Link from '../components/Link';
import Team from '../components/Team';
import FooterLarge from '../components/FooterLarge';

const Funds = () => {
    return (
        <div>
            <Header/>
            <section className="container-fluid bg-gradient-offset mb-xl-4">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-6">
                            <h1 className="mt-xl-5">
                                The Caiman Funds
                            </h1>
                            <p className="sub-head mt-5 mb-2 pe-xl-5">
                                Whether you are looking to <strong>diversify your portfolio</strong>, build wealth upon <strong>digital assets</strong>                         or hedge against inflation. We aim to have a fund specifically designed for your needs in the crypto universe.
                            </p>
                            <span className="text-muted">
                                Have an interest in a fund we don't currently offer? Please <Link to='/contact'>contact us</Link>. We would love to hear your ideas.</span>
                            <span className="p-4">&nbsp;</span>
                        </Col>
                        <Col className="col-12 col-md-6 mb-5">
                            <FundList/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Team border={false}/>
            <FooterLarge/>
        </div>
    );
};

export default Funds;