import React, { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Route, IsAbsolute } from '../Routes';

export default class Link extends Component {
    render () {
        // Clone properties so we can modify them
        let props = {...this.props};
        const {uri} = Route(props.to || '#');
        // const {uri, app} = Route(props.to || '#');
        props.to = uri;

        if (
            // Not a download link
            typeof props.download === 'undefined'
            // Not a js clickable action
            && typeof props.onClick === 'undefined'
            // An absolute link
            && IsAbsolute(props.to)
        ) {
            // New tab by default
            // if (typeof props.target === 'undefined') {
            //     if (app) {
            //         props.target = 'cfapp';
            //     } else {
            //         props.target = 'cfsite';
            //     }
            // }
            // // Security addition by default
            // if (typeof props.rel === 'undefined') {
            //     props.rel = 'noopener noreferrer';
            // }
            props.href = props.to;
            delete props.to;
            return (
                <a {...props}>{props.children}</a>
            );
        }
        else {
            // Standard React links
            return (
                <ReactLink {...props} />
            );
        }
    }
}