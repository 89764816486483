// Pages
import Home from './pages/home';
import Deposit from './pages/deposit';
import Terms from './pages/terms';
import Contact from './pages/contact';
import Funds from './pages/funds';
import StableFund from './pages/funds/cfds';
import FundI from './pages/funds/cfdi';
import Portfolio from './pages/portfolio';
import Test from './pages/test';

const Production = {
    site: 'caiman.fund',
    app: 'caiman.fund',
    // A unique hash must be present in this
    connectUri: 'https://caiman.fund/portfolio#connect',
};

const Routes = {
    '/': {
        page: Home,
        name: 'Home',
        title: 'The Caiman Fund',
        header: true,
        footer: false,
        portfolio: false,
        app: false,
        exact: true,
        bodyClass: 'home',
    },
    '/portfolio': {
        page: Portfolio,
        name: 'Your Portfolio',
        title: 'My Caiman Fund Portfolio',
        header: false,
        footer: true,
        portfolio: true,
        app: true,
        exact: false,
        bodyClass: 'portfolio',
    },
    '/funds': {
        page: Funds,
        name: 'Funds',
        title: 'Explore the Caiman Funds',
        header: true,
        footer: false,
        portfolio: false,
        app: false,
        exact: true,
        bodyClass: 'funds',
    },
    '/funds/cfdi': {
        page: FundI,
        name: 'Fund I',
        title: 'Caiman Fund I',
        header: false,
        footer: true,
        portfolio: false,
        app: false,
        exact: true,
        bodyClass: 'cfdi',
    },
    '/funds/cfds': {
        page: StableFund,
        name: 'Stable Fund',
        title: 'The Caiman Stable Fund',
        header: false,
        footer: true,
        portfolio: false,
        app: false,
        exact: true,
        bodyClass: 'cfds',
    },
    '/contact': {
        page: Contact,
        name: 'Contact Us',
        title: 'Contact Caiman Fund',
        header: true,
        footer: true,
        portfolio: true,
        app: false,
        exact: true,
        bodyClass: 'contact',
    },
    '/deposit': {
        page: Deposit,
        name: 'Deposit Funds',
        title: 'Deposit into Caiman Fund',
        header: false,
        footer: true,
        portfolio: false,
        app: true,
        exact: false,
        bodyClass: 'deposit',
    },
    '/terms': {
        page: Terms,
        name: 'Terms and Conditions',
        title: 'Caiman Fund Terms and Conditions',
        header: false,
        footer: true,
        portfolio: false,
        app: false,
        exact: true,
        bodyClass: 'terms',
    },
    '/test': {
        page: Test,
        name: 'Test',
        title: 'Test',
        header: false,
        footer: false,
        portfolio: false,
        app: true,
        exact: true,
        bodyClass: 'test',
    },
};

export function isOnProductionSite () {
    return Production.site === window.location.host;
}

export function isOnProductionApp () {
    return Production.app === window.location.host;
}

export function launchLink() {
    if (isOnProductionSite()) {
        return productionAppConnectUri();
    }
    const {uri} = Route('/portfolio');
    return uri + '#' + productionAppConnectUri().split('#')[1];
}

export function routeIsApp (uri) {
    if (typeof uri == 'string') {
        if (uri.indexOf(Production.app) !== -1) {
            return true;
        }
        for (let route in Routes) {
            if (Routes[route].exact) {
                if (route === uri) {
                    return Routes[route].app;
                }
            }
            else {
                route = route.replace('*', '')
                    .replace(/\/$/, '');
                if (uri.indexOf(route) === 0) {
                    return Routes[route].app;
                }
            }
        }
    }
    return false;
}

export function Route (uri) {
    // Discern if this link is to the app or not
    let app = routeIsApp(uri);

    if (!IsAbsolute(uri)) {
        // Portfolio --> Site
        if (isOnProductionApp() && !app) {
            uri = 'https://' + Production.site + uri;
        }

        // Site --> Portfolio
        if (isOnProductionSite() && app) {
            uri = 'https://' + Production.app + uri;
        }
    }

    // Not in production, or no change, do nothing.
    return {uri, app};
}

export function IsAbsolute (uri) {
    if (typeof uri === 'undefined') {
        return false;
    }
    return uri.indexOf('http') === 0;
}

export function isConnectingByLink () {
    let isConnecting = false;
    if (window.location.hash === '#' + productionAppConnectUri().split('#')[1]) {
        isConnecting = true;
        if (window.location.host !== 'localhost:3000') {
            window.history.pushState('', document.title, window.location.pathname + window.location.search);
        }
    }
    return isConnecting;
}

export function productionAppConnectUri () {
    return Production.connectUri;
}

export default Routes;
