import React, { Component } from 'react';
import getWalletConnect from '../getWalletConnect';
import CaimanFund from '../contracts/CaimanFundV1.json';
import {
    Button,
    CloseButton,
    Col,
    Container,
    Dropdown,
    Modal,
    Row
} from 'react-bootstrap';
import ReleaseForm from './ReleaseForm';
import Link from './Link';
import { Redirect } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
import { UrisToTokens, DemoToken, TokensByFund } from '../Tokens';
import Funds from '../Funds';
import {
    isConnectingByLink,
    isOnProductionSite, launchLink, routeIsApp
} from '../Routes';

const FUND_MIN = 1;
const FUND_MAX = 10;
const TRANSACTION_LINKS = 'https://etherscan.io/tx/';

class Wallet extends Component {
    constructor (props) {
        super(props);
        const web3 = window.cfweb3 || this.props.web3 || null;
        const accounts = this.storageGet('cfweb3accounts', []);
        let stage = 'ready';
        if (accounts.length && window.cfcontract) {
            stage = 'connected';
        }
        else {
            if (accounts.length) {
                stage = 'connecting';
            } else {
                if (isConnectingByLink()) {
                    if (props.modal) {
                        stage = 'modal';
                    }
                    else {
                        stage = 'connecting';
                    }
                }
            }
        }
        this.state = {
            web3: web3,
            networkId: this.storageGet('cfweb3chainid', null),
            nfts: null,
            tokens: this.storageGet('cftokens', []),
            funds: this.storageGet('cffunds', []),
            accounts: accounts,
            stage,
            error: null,
            contract: window.cfcontract || null,
            requests: [],
            nonce: 0,
            investLink: '/deposit'
        };
        this.connect = this.connect.bind(this);
        this.accountsChanged = this.accountsChanged.bind(this);
        this.chainChanged = this.chainChanged.bind(this);
        this.blockie = this.blockie.bind(this);
        this.loadContract = this.loadContract.bind(this);
        this.requestRelease = this.requestRelease.bind(this);
        this.offlineMode = this.offlineMode.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.getAccounts = this.getAccounts.bind(this);
        this._isMounted = false;
    }

    accountsChanged (accounts) {
        if (accounts.length) {
            if (this.state.accounts !== accounts) {
                console.log('Account ' + (accounts[0] || ''));
                // Update session account storage.
                this.storageSet('cfweb3accounts', accounts);
                // Reset tokens and nfts as well.
                return this.setState({
                    accounts,
                    nfts: null,
                    tokens: [],
                    stage: 'connected'
                }, this.loadContract);
            }
        }
        else {
            console.log('Account disconnected');
            this.storageSet('cfweb3accounts', []);
            return this.setState({
                accounts,
                nfts: null,
                tokens: [],
                stage: 'ready'
            }, this.disconnect);
        }
    }

    blockie (account) {
        // Ported Blockies implementation to match Etherscan.
        let randSeed = new Array(4);

        function seedRand (seed) {
            for (let i = 0; i < randSeed.length; i++) {
                randSeed[i] = 0;
            }
            for (let i = 0; i < seed.length; i++) {
                randSeed[i % 4] = ((randSeed[i % 4] << 5) - randSeed[i % 4]) + seed.charCodeAt(i);
            }
        }

        function rand () {
            let t = randSeed[0] ^ (randSeed[0] << 11);
            randSeed[0] = randSeed[1];
            randSeed[1] = randSeed[2];
            randSeed[2] = randSeed[3];
            randSeed[3] = (randSeed[3] ^ (randSeed[3] >> 19) ^ t ^ (t >> 8));
            return (randSeed[3] >>> 0) / ((1 << 31) >>> 0);
        }

        function createColor () {
            let h = Math.floor(rand() * 360);
            let s = ((rand() * 60) + 40) + '%';
            let l = ((rand() + rand() + rand() + rand()) * 25) + '%';
            return 'hsl(' + h + ',' + s + ',' + l + ')';
        }

        function createImageData (size) {
            let width = size;
            let height = size;
            let dataWidth = Math.ceil(width / 2);
            let mirrorWidth = width - dataWidth;
            let data = [];
            for (let y = 0; y < height; y++) {
                let row = [];
                for (let x = 0; x < dataWidth; x++) {
                    row[x] = Math.floor(rand() * 2.3);
                }
                let r = row.slice(0, mirrorWidth);
                r.reverse();
                row = row.concat(r);
                for (let i = 0; i < row.length; i++) {data.push(row[i]);}
            }
            return data;
        }

        function createCanvas (imageData, color, scale, bgColor, spotColor) {
            let c = document.createElement('canvas');
            let width = Math.sqrt(imageData.length);
            c.width = c.height = width * scale;
            let cc = c.getContext('2d');
            cc.fillStyle = bgColor;
            cc.fillRect(0, 0, c.width, c.height);
            cc.fillStyle = color;
            for (let i = 0; i < imageData.length; i++) {
                let row = Math.floor(i / width);
                let col = i % width;
                cc.fillStyle = (imageData[i] === 1) ? color : spotColor;
                if (imageData[i]) {cc.fillRect(col * scale, row * scale, scale, scale);}
            }
            return c;
        }

        function createIcon (opts) {
            opts = opts || {};
            let size = opts.size || 8;
            let scale = opts.scale || 4;
            let seed = opts.seed || Math.floor((Math.random() * Math.pow(10, 16))).toString(16);
            seedRand(seed);
            let color = opts.color || createColor();
            let bgColor = opts.bgColor || createColor();
            let spotColor = opts.spotColor || createColor();
            let imageData = createImageData(size);
            return createCanvas(imageData, color, scale, bgColor, spotColor);
        }

        return createIcon({
            seed: account.toLowerCase(),
            scale: 5
        }).toDataURL();
    }

    async chainChanged (input) {
        const {web3, error, stage, networkId} = this.state;
        const errMsg = 'Please connect your wallet to the Ethereum Mainnet';
        const nid = typeof input == 'string' ? web3.utils.hexToNumber(input) : input;
        if (!this.getNetwork(nid)) {
            console.log('Chain invalid', nid);
            this.setState({
                networkId: nid,
                stage: 'error',
                error: errMsg
            });
            return false;
        }
        else {
            if (stage === 'error' && error === errMsg) {
                console.log('Chain valid', nid);
                this.setState({
                    networkId: nid,
                    error: null,
                }, this.connect);
            } else if (nid !== networkId) {
                console.log('Chain valid', nid);
                await this.setState({
                    networkId: nid
                }, this.connect);
            }
            else {
                await this.getAccounts();
            }
            return true;
        }
    }

    async componentDidMount () {
        const {stage} = this.state;
        if (stage === 'connecting') {
            await this.connect();
        }
        else {
            if (stage !== 'connected') {
                await this.offlineMode();
            } else {
                await this.connect();
            }
        }
        this._isMounted = true;
    }

    async getAccounts(){
        const {web3, accounts} = this.state;
        if (!accounts || !accounts.length) {
            // Do not attempt eth_requestAccounts with WalletConnect
            if (typeof web3.currentProvider.infuraId === 'undefined' && !window.awaitingAccounts) {
                window.awaitingAccounts = true;
                web3.currentProvider.request({
                    method: 'eth_requestAccounts',
                }).then(async () => {
                    window.awaitingAccounts = false;
                    this.accountsChanged(await web3.eth.getAccounts());
                }).catch((err) => {
                    if (err.code === 4001) {
                        // EIP-1193 userRejectedRequest error
                        // If this happens, the user rejected the
                        // connection request.
                        this.disconnect();
                    }
                    else {
                        console.error(err);
                    }
                    window.awaitingAccounts = false;
                });
            } else {
                this.accountsChanged(await web3.eth.getAccounts());
            }
        }
        else {
            this.accountsChanged(await web3.eth.getAccounts());
        }
    }

    async connect () {
        console.log('Connecting');
        this.setState({
            stage: 'connecting'
        }, async () => {
            try {
                // Get network provider and web3 instance.
                const web3 = window.cfweb3 || await getWalletConnect(this.accountsChanged, this.chainChanged, this.disconnect);
                window.cfweb3 = web3;
                this.setState({
                    web3
                }, async () => {
                    const networkId = await web3.eth.net.getId();
                    await this.chainChanged(networkId);
                });
            }
            catch (error) {
                console.warn('disconnecting', error);
                this.setState({
                    stage: 'error',
                    error: 'Please connect your MetaMask (or similar) wallet'
                });
            }
        });
    }

    async disconnect (code, reason) {
        console.log('disconnecting', code, reason);
        window.cftokens = [];
        window.cffunds = {};
        window.cffunddata = {};
        window.cfweb3accounts = [];
        window.sessionStorage.clear();
        this.setState({
            nfts: null,
            tokens: [],
            funds: {},
            accounts: [],
            stage: 'ready',
        }, this.offlineMode);
    }

    async getFunds () {
        const {contract, funds, stage} = this.state;
        if (funds.length) {
            return funds;
        }
        let newFunds = funds || {};
        if (stage === 'connected') {
            for (let i = FUND_MIN; i <= FUND_MAX; i++) {
                let fund = await contract.methods.getFund(i).call();
                if (fund === null) {
                    newFunds = Funds;
                    // no connection
                    break;
                }
                if (!fund.symbol.length && !fund.name.length) {
                    // Stop searching for funds when we hit an empty symbol
                    break;
                }
                fund = {
                    name: fund.name,
                    symbol: fund.symbol,
                    destination: fund.destination,
                    active: fund.active,
                };
                // Combine contract fund status with the Funds object for
                // off-chain data
                if (typeof Funds[i] !== 'undefined') {
                    for (const c in Funds[i]) {
                        if (typeof fund[c] === 'undefined') {
                            fund[c] = Funds[i][c];
                        }
                    }
                }
                newFunds[i] = fund;
            }
        }
        else {
            newFunds = Funds;
        }
        this.storageSet('cffunds', newFunds);
        await this.setState({funds: newFunds});
        return newFunds;
    }

    async getNFTs (callback) {
        const {accounts, contract} = this.state;
        let investLink = '/deposit';
        let nfts = [];
        if (contract && accounts.length > 0) {
            let uris = await contract.methods.tokenURIsByOwner(accounts[0]).call();
            console.log('Wallet Token URIs: ' + uris);
            let tokens = UrisToTokens(uris);
            console.log('Wallet Tokens', tokens);
            let funds = await this.getFunds();

            // Filter down by active funds
            if (this.props.fundId) {
                tokens = TokensByFund(this.props.fundId, false, tokens).tokens;
                if (
                    typeof funds[this.props.fundId] !== 'undefined'
                    && typeof funds[this.props.fundId].symbol !== 'undefined'
                ) {
                    investLink += '/' + funds[this.props.fundId].symbol.toLowerCase();
                }
            }
            else {
                // Add demo tokens for funds that the user is not invested in.
                let NewTokens = [];
                for (const f in funds) {
                    if (funds[f].active && funds[f].display) {
                        NewTokens = NewTokens.concat(TokensByFund(f, false, tokens).tokens);
                    }
                }
                tokens = NewTokens;
            }
            console.log('Stored Tokens', tokens);
            this.storageSet('cftokens', tokens);

            // Get requests to withdraw for these NFTs
            let transactionHashes = [];
            let events = await contract.getPastEvents('Request', {
                filter: {'tokenId': Object.keys(tokens)},
                fromBlock: 0
            });
            for (let i = 0; i <= events.length - 1; i++) {
                // if (!events[i].type !== 'mined') {
                //     continue;
                // }
                transactionHashes[events[i].returnValues.tokenId] = events[i].transactionHash;
            }

            let d = 0;
            for (let t = 0; t < tokens.length; t++) {
                try {
                    let src = tokens[t].uri.replace('/token/', '/token/svg/');
                    console.log(src);
                    let style = {'animationDelay': d + 's'};
                    let transactionHash = transactionHashes[t[0]] || false;
                    let p = {
                        tokenId: t,
                        depositTime: tokens[t].depositTime,
                        releaseTime: new Date(tokens[t].depositTime.setMonth(tokens[t].depositTime.getMonth() + 9)),
                        fundId: tokens[t].fundId,
                        fund: funds[tokens[t].fundId].name,
                        sourceId: tokens[t].sourceId,
                        amount: tokens[t].amount,
                        confirmed: tokens[t].status === 1,
                        label: 'Redeem Investment',
                        src: src,
                        release: this.requestRelease,
                        transactionHash: transactionHash,
                    };
                    if (p.releaseTime.getTime() > (new Date()).getTime()) {
                        p.label = 'Redeem Investment Early';
                    }
                    let actions = '';
                    let transactionLink = '';
                    if (transactionHash) {
                        let link = TRANSACTION_LINKS + transactionHash;
                        transactionLink = (
                            <span>
                            <a href={link}
                               target="_blank"
                               className="transaction-link"
                               rel="noreferrer">Redemption Requested
                            </a>
                            </span>
                        );
                    }
                    if (p.confirmed && !transactionHash) {
                        let opensea = 'https://opensea.io/' + contract.options.address + '/' + p.tokenId;
                        actions = (
                            <div className="nft-actions flex-shrink-1">
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary">
                                        &bull;&bull;&bull;
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ReleaseForm props={p}/>
                                        <Dropdown.Item
                                            href={opensea}
                                            target="_blank">View on OpenSea</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        );
                    }
                    let label = 'CFD ' + p.tokenId;
                    nfts.push(
                        <div key={t}
                             className="nft fade-in-bg-gray">
                            <div className="anim"
                                 style={style}>
                                <div className="flip-in-hor-bottom-2"
                                     style={style}>
                                    <div
                                        className="tilt-in-top-2"
                                        style={style}>
                                        <Tilt glareEnable={true}
                                              glareMaxOpacity={0.8}
                                              glareColor="#ffffff"
                                              glarePosition="bottom"
                                              glareBorderRadius="0px">
                                            <img src={src} alt={label}/>
                                        </Tilt>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex pt-4">
                                <div className="w-100">
                                    <span>{p.fund}</span><br/>
                                    <span>{label}</span><br/>
                                    {transactionLink}
                                </div>
                                {actions}
                            </div>
                        </div>
                    );
                    d += .5;
                }
                catch (e) {
                    console.error(e);
                }
            }
            this.setState({
                nfts,
                tokens,
                investLink
            });
        } else {
            this.storageSet('cftokens', []);
        }
        return nfts;
    }

    getNetwork (networkId) {
        return CaimanFund.networks[networkId] || false;
    }

    async loadContract () {
        const {
            networkId,
            accounts,
            web3,
            contract
        } = this.state;
        const network = this.getNetwork(networkId);
        if (
            web3
            && network
            && (typeof accounts !== 'undefined' && accounts !== null && accounts.length > 0)
        ) {
            if (!contract) {
                let newContract = new web3.eth.Contract(
                    CaimanFund.abi,
                    network.address,
                );
                window.cfcontract = newContract;
                this.setState({
                    contract: newContract
                }, this.getNFTs);
            } else {
                await this.getNFTs();
            }
        }
    }

    async offlineMode () {
        console.log('offline mode');
        let tokens = this.storageGet('cftokens', []) || [];
        if (typeof this.props.fundId !== 'undefined') {
            // Fund selected, one demo token.
            tokens = TokensByFund(this.props.fundId).tokens;
        }
        else {
            let funds = await this.getFunds();
            // Multiple demo tokens, one for each fund
            for (const f in funds) {
                if (funds[f].active && funds[f].display) {
                    let token = DemoToken(f);
                    if (typeof token.tokenId === 'undefined') {
                        console.error('token issue', token);
                    }
                    tokens[token.tokenId] = token;
                }
            }
        }
        this.storageSet('cftokens', tokens);
    }

    redirectTo = (redirect) => {
        if (window.location.pathname !== redirect) {
            this.setState(
                {redirect: redirect},
                () => this.setState({redirect: false})
            );
        }
    };

    render () {
        const {redirect} = this.state;
        if (redirect && window.location.pathname !== redirect) {
            return <Redirect to={redirect}/>;
        }

        if (this.props.nfts) {
            return this.renderNFTs();
        }
        else {
            return this.renderLogin();
        }
    }

    renderLogin () {
        const {accounts, stage, error} = this.state;

        const modal = (
            <LoginModal
                show={stage === 'modal'}
                connect={this.connect}
                onHide={async () => {
                    setTimeout(async () => {
                        if (this.state.stage === 'modal' && this._isMounted) {
                            this.setState({stage: 'ready'});
                        }
                    }, 400);
                }}
            />
        );

        switch (stage) {
            case 'modal':
                return (
                    <>
                        {modal}
                    </>
                );
            case 'connecting':
                return (
                    <>
                        <div id="wallet" className="connecting bg-gradient-left"
                             onClick={this.disconnect}>
                            <span className="bg-gradient-bar">&nbsp;</span>
                            <span className="ellipses">Connecting</span>
                        </div>
                        {modal}
                    </>
                );
            case 'ready':
                if (isOnProductionSite()) {
                    return (
                        <>
                            <div id="wallet" className={stage}>
                                <Link
                                    className="launch btn btn-sm btn-outline-primary btn-simple"
                                    to={launchLink()}>
                                    Launch App
                                </Link>
                            </div>
                        </>
                    );
                }
                else {
                    return (
                        <>
                            <div id="wallet" className={stage}>
                                <button
                                    className="connect btn btn-sm btn-outline-primary btn-simple"
                                    onClick={async () => {
                                        this.connect();
                                    }}>
                                    Connect wallet
                                </button>
                            </div>
                            {modal}
                        </>
                    );
                }
            case 'connected':
                let part2 = accounts[0].substring(accounts[0].length - 9);
                let part1 = accounts[0].substring(0, accounts[0].length - part2.length);
                return (
                    <>
                        <div id="wallet" className="connected bg-gradient-left"
                             onClick={() => {
                                 this.redirectTo('/portfolio');
                             }}>
                            <span className="bg-gradient-bar">&nbsp;</span>
                            <div className="portfolio-and-account">
                                <div className="your-portfolio">
                                    {routeIsApp(window.location.pathname) ? 'Your portfolio' : 'Launch App'}
                                </div>
                                <div className="account">
                                    <span className="part1">{part1}</span>
                                    <span className="part2">{part2}</span>
                                </div>
                            </div>
                            <img src={this.blockie(accounts[0])}
                                 alt={accounts[0]}/>
                        </div>
                        {modal}
                    </>
                );
            case 'error':
            default:
                return (
                    <>
                        <div id="wallet" className={stage}>
                            <span className="btn btn-sm btn-warning">
                                {error}
                            </span>
                        </div>
                        {modal}
                    </>
                );
        }
    }

    renderNFTs () {
        const {nfts, investLink} = this.state;
        if (!nfts) {
            return null;
        }
        return (
            <div className="container-fluid bg-dark text-light">
                <Container>
                    <Col className="col-xl-10 offset-xl-1">
                        <Row className="pt-5 bg-dark nft-container">
                            <h4 className="mt-4">Your Positions</h4>
                        </Row>
                        <Row className="pt-5 bg-dark nft-container pb-5">
                            {nfts}
                            <Link
                                className="nft fade-in-bg-gray d-flex align-items-center float-end"
                                to={investLink}>
                                <div className="nft-add-position-plus align-middle m-auto">
                                    <div
                                        className="text-nowrap nft-add-position m-auto">
                                        Add Position
                                    </div>
                                </div>
                            </Link>
                        </Row>
                    </Col>
                </Container>
            </div>
        );
    }

    async requestRelease (tokenId, destinationWallet) {
        const {contract, accounts, nonce} = this.state;
        if (typeof destinationWallet === 'undefined') {
            destinationWallet = '';
        }
        await contract.methods.requestRelease(tokenId, destinationWallet).send({
            from: accounts[0]
        });
        this.setState({nonce: nonce + 1});
    }

    storageGet = (key, def) => {
        if (typeof window[key] !== 'undefined') {
            return window[key];
        }
        let str = window.sessionStorage.getItem(key) || '';
        if (str.length) {
            try {
                return JSON.parse(str);
            }
            catch (e) {
                return str;
                // console.error(e);
            }
        }
        return def;
    };

    storageSet = (key, value) => {
        window[key] = value;
        window.sessionStorage.setItem(key, JSON.stringify(value));
    };
}

function LoginModal (props) {
    const [modalShow, setModalShow] = React.useState(props.show);

    return (
        <Modal
            size="md"
            show={modalShow}
            aria-labelledby="contained-modal-title-login"
            centered
            // Prevent discard without clicking/cancelling.
            onHide={() => {}}
            // Allow closing by clicking on overlay.
            // onHide={props.onHide}
        >
            <CloseButton aria-label="Hide"
                         onClick={() => {
                             setModalShow(false);
                             props.onHide();
                         }}>
                <div className="cf-cancel">&nbsp;</div>
            </CloseButton>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-login">
                    Login and Authorize your Wallet
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                This dapp requires access to your wallet, please login and authorize access to your account to continue.
            </Modal.Body>
            <Modal.Footer className=" bg-dark">
                <p className="me-auto">
                    <span
                        className="small link"
                        onClick={async () => {
                            setModalShow(false);
                            props.onHide();
                        }}>
                        View Demo
                    </span>
                </p>
                <Button
                    onClick={async () => {
                        setModalShow(false);
                        props.connect();
                    }}
                    variant="outline-primary btn-sm btn-light">Connect wallet</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Wallet;
