import { Container } from 'react-bootstrap';
import React, { Component } from 'react';
import Navigation from './Navigation';

class Footer extends Component {

    render () {
        let className = 'container-fluid bg-dark';
        if (this.props.bright) {
            className = 'container-fluid bg-light';
        }
        return (
            <footer className={className}>
                <Container>
                    <Navigation isFooter={true} isHeader={false}/>
                </Container>
            </footer>
        );
    }
}

export default Footer;
