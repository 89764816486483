import { Col, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import PortfolioInvestment from './PortfolioInvestment';

class PortfolioInvestmentDemo extends Component {

    render () {
        return (
            <Row>
                <h4 className="text-center mt-5 pt-2">Manage your positions in one place. Easily.</h4>
                <p className="mt-5 text-center">
                        Watch your positions change immediately after joining.
                </p>
                <Col className="d-flex justify-content-center col-12">
                    <div className="cf-demo-window bg-light text-dark">
                        <PortfolioInvestment fundId={this.props.fundId}/>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default PortfolioInvestmentDemo;
