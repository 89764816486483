// const CHART_URL = 'http://127.0.0.1:8787/api/chart';
const CHART_URL = 'https://caiman.fund/api/chart';

/**
 * Parse a token URI into variables we may need.
 *
 * @param uri
 * @returns {{sourceId, fundId, amount, tokenId, depositTime, status}}
 * @constructor
 */
export function UriToToken (uri) {
    let tokenId, depositTime, fundId, sourceId, amount, status, token;
    let parts = uri.split('/');
    if (parts.length) {
        token = parts[parts.length - 1];
        [tokenId, depositTime, fundId, sourceId, amount, status] = token.split('.');
        tokenId = parseInt(tokenId);
        depositTime = new Date(parseInt(depositTime) * 1000);
        fundId = parseInt(fundId);
        sourceId = parseInt(sourceId);
        status = parseInt(status);
    }
    return {tokenId, depositTime, fundId, sourceId, amount, status, token, uri};
}

/**
 * Parse multiple token URIs into an array of tokens, keyed by ID.
 *
 * @param uris
 * @returns {{}}
 * @constructor
 */
export function UrisToTokens (uris) {
    let tokens = [];

    if (uris !== null) {
        for (let i = 0; i < uris.length; i++) {
            if (typeof uris[i] !== 'string' || uris[i].length === 0) {
                continue;
            }
            tokens.push(UriToToken(uris[i]));
        }
    }

    return tokens;
}

export function tokensToString (tokens) {
    let tokenStrings = [];
    for (let i = 0; i < tokens.length; i++) {
        tokenStrings.push(tokens[i].token);
    }

    return tokenStrings.join(',');
}

/**
 * Render a demo token for a specific fund
 *
 * @param fundId
 * @param tokenId
 * @returns {{sourceId: number, fundId, amount: number, tokenId, depositTime:
 *     number, status: number}}
 * @constructor
 */
export function DemoToken (fundId, tokenId) {

    let d = new Date();
    return UriToToken('https://caiman.fund/token/' +
        Object.values({
            tokenId: tokenId,
            // 11 months ago roughly
            depositTime: Math.round(d.setUTCMonth(d.getUTCMonth() - 11) / 1000),
            fundId: fundId,
            // 100k USDC
            sourceId: 1,
            amount: 100100100000,
            status: 2,
        }).join('.'));
}

/**
 * Given a FundId, get all tokens that apply for current wallet, or fill with a
 * demo token
 *
 * @param fundId
 * @param demoOnly
 * @param cftokens
 * @returns {{demoId: number, tokens: *[]}}
 * @constructor
 */
export function TokensByFund (fundId, demoOnly, cftokens) {
    cftokens = (cftokens !== 'undefined' && cftokens !== null ? cftokens : window.cftokens) || [];
    let tokens = [];
    let demoId = 0;
    fundId = parseInt(fundId);
    demoOnly = demoOnly || false;
    if (!demoOnly && cftokens.length) {
        for (let t = 0; t < cftokens.length; t++) {
            if (cftokens[t].fundId === fundId) {
                tokens.push(cftokens[t]);
            }
        }
    }
    if (tokens.length === 0) {
        console.log('Generating demo token for fund ' + fundId);
        // Use a demo token
        demoId++;
        tokens.push(DemoToken(fundId, '0' + demoId));
    }
    else {
        demoId = 1;
        for (let t = 0; t < tokens.length; t++) {
            if (tokens[t].status !== 2) {
                demoId = 0;
                break;
            }
        }
    }
    return {tokens, demoId};
}

/**
 * Get the current token metadata from one or more tokens via the api
 *
 * @param tokens
 * @returns {Promise<*>}
 * @constructor
 */
export async function TokensMeta (tokens) {

    // Default zoom level for just grabbing value.
    const zoom = '1d';

    let tokenStrings = [];
    for (const t in tokens) {
        tokenStrings.push(tokens[t].token);
    }

    const url = [CHART_URL, zoom, tokenStrings.join(',')].join('/');
    console.log('FETCH', url);
    let data = await fetch(url)
        .then(response => response.json());

    return data.meta || {};
}