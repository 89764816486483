import React, { Component } from 'react';
import PortfolioInvestmentExpanded from './PortfolioInvestmentExpanded';
import { TokensByFund } from '../Tokens';
import LoadingContent from './LoadingContent';
import Funds from '../Funds';

class PortfolioInvestment extends Component {

    constructor (props) {
        super(props);

        this.state = {
            tokens: this.storageGet('cftokens', []),
            demoOnly: this.props.demoOnly || false,
            fundId: this.props.fundId,
        };
        this._isMounted = false;
        clearInterval(window.pireloader);
    }

    storageGet = (key, def) => {
        if (typeof window[key] !== 'undefined') {
            return window[key];
        }
        let str = window.sessionStorage.getItem(key) || '';
        if (str.length) {
            try {
                let obj = JSON.parse(str);
                if (typeof obj === 'object') {
                    for (const o in obj){
                        for (const k in obj[o]){
                            if (k === 'depositTime') {
                                obj[o][k] = new Date(obj[o][k]);
                            }
                        }
                    }
                }
                return obj;
            }
            catch (e) {
                return str;
                // console.error(e);
            }
        }
        return def;
    };

    componentDidMount () {
        const {demoOnly} = this.state;
        this._isMounted = true;
        if (!demoOnly) {
            window.pireloader = setInterval(() => {
                if (this._isMounted) {
                    const tokens = this.storageGet('cftokens', []);
                    if (!this.arrEq(this.state.tokens, tokens)) {
                        clearInterval(window.pireloader);
                        console.log('Reloading Portfolio Investment');
                        this.setState({
                            tokens
                        });
                    }
                }
                else {
                    clearInterval(window.pireloader);
                }
            }, 500);
        } else {
            clearInterval(window.pireloader);
        }
    }

    componentWillUnmount () {
        clearInterval(window.pireloader);
        this._isMounted = false;
    }

    arrEq (a, b) {
        // if (a !== b) {
        //     return false;
        // }
        return JSON.stringify(a) === JSON.stringify(b);
    }

    render () {
        const {demoOnly, fundId} = this.state;

        // Filter out tokens based on the user's fund selection
        let {tokens, demoId} = TokensByFund(fundId, demoOnly, this.storageGet('cftokens', []));
        return (
                <PortfolioInvestmentExpanded tokens={tokens}
                                             demo={demoId > 0}/>
        );
    }
}

export default PortfolioInvestment;
