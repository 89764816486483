import { Col, Row } from 'react-bootstrap';
import React, { Component } from 'react';

class LoadingContent extends Component {
    render () {
        return (
            <section className="container-fluid loading-container h-100">
                <Row className="h-100">
                    <Col className="text-center d-flex h-100">
                        <img className="loading align-self-center"
                             alt="Caiman Fund"
                             src="/img/loading-continuous.svg"
                             style={{'maxWidth': '25%', 'margin': 'auto', 'opacity': '.4'}}/>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default LoadingContent;