import { Card, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import Link from './Link';
import Funds from '../Funds';

class FundList extends Component {

    constructor (props) {
        super(props);
        this.state = {
            web3: null,
            networkId: null,
        };
    }

    render () {

        let funds = [];
        for (const f in Funds) {
            if (!Funds[f].active || !Funds[f].display) {
                continue;
            }
            let uri = '/funds/' + Funds[f].symbol;
            let className = 'cf-fund-link cf-fund-' + Funds[f].symbol;
            let minimumInvestment = '';
            if (Funds[f].min_investment.length) {
                minimumInvestment = (
                    <div className="cf-fund-min-investment">
                        Minimum Investment: <strong>{Funds[f].min_investment}</strong>
                    </div>
                );
            }
            // let atp = '';
            // if (Funds[f].atp.length) {
            //     atp = (
            //         <div className="cf-fund-atp">
            //             All-time performance: {Funds[f].atp}
            //         </div>
            //     );
            // }
            let assets = '';
            if (Funds[f].assets.length) {
                assets = (
                    <div className="cf-fund-assets">
                        Assets: <strong>{Funds[f].assets}</strong>
                    </div>
                );
            }
            funds.push(
                <Row className="mt-4" key={f}>
                    <Link to={uri} className={className}>
                        <Card className="border-0 cf-arrow-right">
                            <div className="card-body ps-3 pb-4 ps-4">
                                <h5 className="card-title">{Funds[f].name}</h5>
                                <div className="card-text pt-2">
                                    {minimumInvestment}
                                    {assets}
                                    {/*{atp}*/}
                                </div>
                            </div>
                        </Card>
                    </Link>
                </Row>
            );
        }

        return (
            <div className="cf-fund-list">
                {funds}
            </div>
        );
    }
}

export default FundList;