import React, { Component } from 'react';
import './App.scss';
import Routes from './Routes';
import NotFound from './pages/404';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

// Do not run logrocket in local development.
if (window.location.href.indexOf(':3000') === -1) {
    setupLogRocketReact(LogRocket);
    LogRocket.init('crocpot/caiman-fund');
}

export default class App extends Component {

    render () {
        // Prevent post on refresh.
        if (typeof window.history.replaceState === 'function') {
            window.history.replaceState(null, null, window.location.href);
        }

        function getPages () {
            let pages = [];
            for (let [route, obj] of Object.entries(Routes)) {
                pages.push(
                    <Route exact={obj.exact} path={route}
                           component={obj.page}
                           key={obj.name}/>
                );
                if (window.location.pathname.indexOf(route) === 0) {
                    document.body.classList.add(obj.bodyClass);
                }
                else {
                    document.body.classList.remove(obj.bodyClass);
                }
            }
            return pages;
        }

        window.addEventListener('popstate', function (event) {
            getPages();
        });
        return <BrowserRouter>
            <Switch>
                {getPages()}
                <Route exact path="/404" component={NotFound}/>
                <Redirect to="/404"/>
            </Switch>
        </BrowserRouter>;
    }
}