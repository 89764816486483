import { Card, Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import Link from '../../components/Link';
import Header from '../../components/Header';
import Team from '../../components/Team';
import FooterLarge from '../../components/FooterLarge';

const StableFund = () => {
    return (
        <div>
            <Header/>
            <section className="container-fluid bg-gradient-offset mb-xl-4">
                <Container>
                    <Row>
                        <Col className="col-12 col-xl-6 col-sm-6">
                            <h1 className="mt-xl-5">
                                Caiman Stable Fund
                            </h1>
                            <p className="sub-head mt-5 mb-5 pe-xl-5">
                                The Caiman Stable Fund <strong>focuses on stable assets</strong> and provides safe asset vehicles for fund depositors to find exposure to the digital asset class while reducing risk by including only those digital assets closely tied to traditional market stable fiat assets.
                            </p>
                            <div>
                                <Link className="btn btn-outline-primary"
                                      to='/deposit/cfds'>
                                    Start&nbsp;Today
                                </Link>
                                &nbsp;
                                <Link download
                                      className="btn btn-link link-dark fw-bold d-block d-md-inline-block"
                                      to='/assets/20210604-CaimanStableFund.pdf'
                                      onClick={()=>{window.open('/assets/20210604-CaimanStableFund.pdf');return false;}}>
                                    Download&nbsp;presentation
                                </Link>
                            </div>
                        </Col>
                        <Col className="col-12 col-sm-4 offset-sm-2 mb-5">
                            <h3>Summary of Terms</h3>
                            <dl className="d-flex justify-content-between">
                                <dt>Minimum Deposit</dt>
                                <dd>$500,000</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Management Fee</dt>
                                <dd>1.5%</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Performance Fee</dt>
                                <dd>10%</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Lock-Up</dt>
                                <dd>12 Months</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Early Withdrawal Penalty</dt>
                                <dd>3.5%</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Qualifications</dt>
                                <dd>Sophisticated</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Manager</dt>
                                <dd>Stockcroc</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Auditor</dt>
                                <dd>TechRate</dd>
                            </dl>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Team/>
            <section className="mt-5 pt-5 mb-5 pb-4">
                <Container>
                    <Row>
                        <Col className="col-12 col-xl-4 offset-xl-1">
                            <h5 className="mb-5 pe-lg-4">
                                The Caiman Stable Fund identifies and generates strong, stable returns<span
                                className="cf-asterix">*</span> to fund participants.
                            </h5>
                            <Card className="border-0 p-4">
                                <span className="cf-asterix asterix-lg">*</span>
                                <strong className="cf-strong">
                                    Historical performance, has demonstrated a stable annualized return with minimal risk to the underlying asset holder.
                                </strong>
                                <div className="d-block d-xxl-flex">
                                    <span className="cf-percentage-lg">
                                        35.65%
                                    </span>
                                    <span className="p-4 mt-auto">
                                        <span className="cf-no-break">ANNUALIZED RATE</span> OF RETURN
                                    </span>
                                </div>
                            </Card>
                        </Col>
                        <Col className="col-12 col-xl-6">
                            <Row className="mt-2 ps-xl-5">
                                <Col className="col-12 col-xl-6 ps-xl-5 pe-xl-3 col-sm-6">
                                    <p>
                                        <span className="cf-icon-fund-04 mb-4">&nbsp;</span>
                                        <strong className="d-block mb-3">Limit turnover</strong>
                                        The Caiman Stable Fund is meant to be a longterm strategic investment vehicle providing stable, reliable returns for an extended duration across all market types.
                                    </p>
                                </Col>
                                <Col className="col-12 col-xl-6 pe-xl-4 col-sm-6">
                                    <p>
                                        <span className="cf-icon-fund-05 mb-4">&nbsp;</span>
                                        <strong className="d-block mb-3">Minimize risk exposure</strong>
                                        This fund seeks to minimize exposure to risk through a stable asset focus and growing value through arbitrage over time.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mt-5 ps-xl-5 mb-xl-5">
                                <Col className="col-12 col-xl-6 ps-xl-5 pe-xl-3 col-sm-6">
                                    <p>
                                        <span className="cf-icon-fund-06 mb-4">&nbsp;</span>
                                        <strong className="d-block mb-3">Remove friction</strong>
                                        By limiting risk exposure and focusing on stable-centric digital assets this fund removes the friction for more traditional market investors.
                                    </p>
                                </Col>
                                <Col className="col-12 col-xl-6 pe-xl-4 col-sm-6">
                                    <p>
                                        <span className="cf-icon-fund-07 mb-4">&nbsp;</span>
                                        <strong className="d-block mb-3">Protect capital</strong>
                                        The fund leaves no doubt over the prime focus of protecting and preserving invested capital while also offering exposure to digital assets.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <FooterLarge/>
        </div>
    );
};

export default StableFund;