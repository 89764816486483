import { Card, Col, Container, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import Link from './Link';
import Funds from '../Funds';
import { TokensByFund, TokensMeta } from '../Tokens';
import LoadingContent from './LoadingContent';
import Message from './Message';
import { Redirect } from 'react-router-dom';

class PortfolioInvestmentList extends Component {

    constructor (props) {
        super(props);
        this.state = {
            tokens: this.storageGet('cftokens', []),
            funds: {},
            accounts: this.storageGet('cfaccounts', []),
            fundData: window.cffunddata || {},
            stage: this.isLoaded() ? 'ready' : 'loading'
        };
        this._isMounted = false;
    }

    arrEq (a, b) {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    // Reload this component when NFTs are found
    componentDidMount () {
        this._isMounted = true;
        this.getData();
    }

    componentWillUnmount () {
        clearInterval(window.pilreloader);
        this._isMounted = false;
    }

    async getData () {
        clearInterval(window.pilreloader);
        const funds = this.storageGet('cffunds', Funds);
        window.cffunddata = window.cffunddata || {};
        const cftokens = this.storageGet('cftokens', []);
        for (const f in funds) {
            if (funds[f].active && funds[f].display) {
                // See if the user has an NFT for this fund.
                let {tokens, demoId} = TokensByFund(f, false, cftokens);
                if (tokens.length) {
                    // @todo - use promise to speed up page here with "then"
                    console.log('Getting portfolio data for tokens', tokens);
                    window.cffunddata[f] = await TokensMeta(tokens);
                    window.cffunddata[f].demoId = demoId;
                }
                else {
                    delete window.cffunddata[f];
                }
            }
        }
        this.setState({
            tokens: cftokens,
            stage: 'ready'
        }, this.startPilReloader);
    }

    isLoaded () {
        return Object.keys(window.cffunddata || {}).length > 0
            && (
                typeof this.state !== 'undefined'
                && this.storageGet('cftokens', []) === this.state.tokens
            );
    }

    render () {
        const {funds, stage} = this.state;

        if (stage === 'loading') {
            return (<LoadingContent/>);
        }
        else if (stage === 'ready') {
            let {
                investments,
                available
            } = this.renderFunds(funds, window.cffunddata);
            let elements = [];
            if (investments.length) {
                elements.push(
                    <div key={elements.length}>
                        <h5 className="pb-4">
                            Participating Funds
                        </h5>
                        <Row className="row-cols-1 row-cols-xl-4 mt-5 mb-5">
                            {investments}
                        </Row>
                    </div>
                );
            }
            else {
                // Only do available demo if no investments above.
                if (available.length) {
                    elements.push(
                        <div key={elements.length}>
                            <h5 className="pb-4">
                                Available Funds
                            </h5>
                            <Row className="row-cols-1 row-cols-xl-4 mt-5 mb-5">
                                {available}
                            </Row>
                        </div>
                    );
                }
            }
            if (!elements.length) {
                return (<LoadingContent/>);
            }
            else {
                return (
                    <div>
                        <Container>
                            <Row>
                                <Col className="col-12">
                                    <span className="p-1">&nbsp;</span>
                                    {elements}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                );
            }
        }
    }

    renderFunds (funds, fundData) {
        let investments = [];
        let available = [];
        let investmentLink;
        for (const f in funds) {
            if (funds[f].active && funds[f].display) {
                if (typeof fundData[f] === 'undefined') {
                    continue;
                }
                let fund = fundData[f];
                console.log('Fund Values: ' + fund.values);
                let header = fund.demoId ? 'Example Fund Position' : 'Your Position';
                let link = '/portfolio/' + Funds[f].symbol.toLowerCase();
                let className = 'bg-gradient-top mb-4' + (fund.demoId ? ' cf-demo' : '');
                let investmentValue = fund.values.investmentValue.replace(/[^\d.]/g, '')
                let totalChange = Math.abs(fund.values.totalChange.replace(/[^\d.]/g, ''))
                let element = (
                    <Col className="col-12 col-xl-4" key={f}>
                        <Card className={className}>
                            <div className="card-body">
                                <h5 className="card-title pb-2">{Funds[f].name}</h5>
                                <p className="cf-investment-muted">
                                    Initial Position: <strong>{fund.values.investmentValue || ''}</strong><br/>
                                    Change: <strong>${totalChange || ''}</strong><br/>
                                    Since: <strong>{fund.times.duration || ''}</strong>
                                </p>
                                <hr/>
                                <p className="cf-investment-header pt-3 mb-0">{header}</p>
                                <p className="cf-investment-usd-md pt-0">{Number(investmentValue) + Number(totalChange)}</p>
                                <p className="cf-investment-stats"><span
                                    className={fund.values.dailyPLClass}>{fund.values.dailyPL}</span> Today
                                </p>
                            </div>
                            <Link to={link}
                                  className="btn btn-outline-light btn-footer cf-no-break">See fund details</Link>
                        </Card>
                    </Col>
                );
                if (typeof fund.demoId !== 'undefined' && fund.demoId > 0) {
                    available.push(element);
                }
                else {
                    investmentLink = link;
                    investments.push(element);
                }
            }
        }

        console.log('fundData', fundData, funds);
        console.log('Investments: ' + investments.length);
        console.log('Available: ' + available.length);

        // If there is only one active investment, redirect the user.
        if (investments.length === 1 && investmentLink.length) {
            investments.push(<Redirect to={investmentLink} key={-1}/>);
        }
        return {investments, available};
    }

    startPilReloader () {
        window.pilreloader = setInterval(() => {
            if (this._isMounted) {
                const funds = this.storageGet('cffunds', {});
                const tokens = this.storageGet('cftokens', []);
                if (
                    !this.arrEq(this.state.funds, funds)
                    || !this.arrEq(this.state.tokens, tokens)
                ) {
                    console.log('Reloading Portfolio Investment List');
                    clearInterval(window.pilreloader);
                    this.setState({
                        tokens,
                        funds,
                        stage: 'loading'
                    }, this.getData);
                }
            }
            else {
                clearInterval(window.pilreloader);
            }
        }, 1000);
    }

    // isLoggedIn () {
    //     return Object.keys(window.cffunds || {}).length > 0;
    // }

    storageGet = (key, def) => {
        if (typeof window[key] !== 'undefined') {
            return window[key];
        }
        let str = window.sessionStorage.getItem(key) || '';
        if (str.length) {
            try {
                return JSON.parse(str);
            }
            catch (e) {
                return str;
                // console.error(e);
            }
        }
        return def;
    };

    storageSet = (key, value) => {
        window[key] = value;
        window.sessionStorage.setItem(key, JSON.stringify(value));
    };
}

export default PortfolioInvestmentList;