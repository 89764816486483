import React, { Component } from 'react';
import Navigation from './Navigation';

class Header extends Component {

    render () {
        return (
            <section className="container">
                <Navigation isFooter={false} wallet={true} isHeader={true} isPortfolio={this.props.isPortfolio || false} />
            </section>
        );
    }
}

export default Header;
