import { Col, Row } from 'react-bootstrap';
import React, { Component } from 'react';

class Message extends Component {
    render () {
        return (
            <section className="container-fluid">
                <Row>
                    <Col className="col-12 mt-4 mb-5 text-center">
                        <p>{this.props.text}</p>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default Message;