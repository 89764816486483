import Header from '../components/Header';
import Wallet from '../components/Wallet';
import Footer from '../components/Footer';
import React from 'react';
// import Investments from '../components/Investments';
import { Container } from 'react-bootstrap';
import PortfolioInvestment from '../components/PortfolioInvestment';
import PortfolioInvestmentList from '../components/PortfolioInvestmentList';
import Funds from '../Funds';

const Portfolio = () => {

    // Discern selected fund if relevant.
    let fundId = null;
    let p = window.location.pathname.split('/');
    if (p.length >= 3) {
        let path = p[p.length - 1].toLowerCase();
        for (const f in Funds) {
            if (Funds[f].symbol.toLowerCase() === path) {
                fundId = f;
                break;
            }
        }
    }
    if (!fundId) {
        // No fund selected, list investments.
        return (
            <>
                <Header isPortfolio={true}/>
                <Container className="mb-5 cf-portfolio-body">
                    <PortfolioInvestmentList/>
                </Container>
                <Footer bright={true}/>
            </>
        );
    } else {
        // Fund selected, show one fund's investments
        return (
            <>
                <Header isPortfolio={true}/>
                <Container className="cf-portfolio-body">
                    <PortfolioInvestment fundId={fundId}/>
                </Container>
                <Wallet nfts={true} fundId={fundId} modal={true}/>
                <Footer bright={true}/>
            </>
        );
    }
};

export default Portfolio;