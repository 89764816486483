import React from 'react';
import { Button, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';
import Link from './Link';

function VerticallyCenteredModal (props) {
    let body = (
        <p className="mt-3">
            You are about to begin the redemption process for investment #{props.props.tokenId} in {props.props.fund}.
            After submitting this request, it will take up to 30 days for your funds to be credited to you.
            When your funds are transferred to you this NFT will be destroyed in exchange.
            <br/><br/>
            Are you sure you want to redeem this investment?
        </p>
    );
    let button = 'Yes, redeem my investment';
    if (props.props.releaseTime.getTime() > (new Date()).getTime()) {
        let releaseDateString = props.props.releaseTime.toLocaleDateString();
        body = (
            <p className="mt-3">
                You are about to begin an <strong>early</strong> redemption request for investment #{props.props.tokenId} in {props.props.fund}.
                Redeeming this investment before <strong>{releaseDateString}</strong> will add a penalty of 3.5%.
                After submitting this request, it will take up to 30 days for your funds to be credited to you.
                When your funds are transferred to you this NFT will be destroyed in exchange.
                <br/><br/>
                Are you sure you wish to redeem this investment with a <strong>3.5%</strong> penalty?
            </p>
        );
        button = 'Yes, redeem my investment early';
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h1 className="text-dark">{props.props.label}</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col sm={3}>
                            <div className="nft-container m-auto">
                                <div className="nft">
                                    <img src={props.props.src} alt="Your NFT"/>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            {body}
                            <p>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <p className="me-auto">
                    Questions? <Link to='/contact'>Contact us</Link>
                </p>
                <Button onClick={props.onHide} variant="info btn-sm">Cancel</Button>
                <Button onClick={async () => {
                    await props.props.release(props.props.tokenId);
                    props.onHide();
                }} variant="outline-primary btn-sm">{button}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default function ReleaseForm (props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Dropdown.Item variant="primary" onClick={() => setModalShow(true)}>
                {props.props.label}
            </Dropdown.Item>
            <VerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                props={props.props}
            />
        </>
    );
}