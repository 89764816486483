/**
 * (for not-connected wallet sessions)
 *
 * When logged in, this is merged with the blockchain equivalent to provide
 * details that are not stored on chain.
 */
const Funds = {
    1: {
        name: 'Caiman Fund I',
        symbol: 'cfdi',
        // Can take deposits
        active: true,
        // Show it in the UI
        display: true,
        description: 'The Caiman Fund I provides investors access to a diversified portfolio focused primarily on digital assets within the decentralized finance ecosystem.',
        assets: 'Bitcoin, Ethereum, and hand-selected alternate assets',
        atp: '170.45%',
        min_investment: '$100,000'
    },
    2: {
        name: 'Caiman Fund II',
        symbol: 'cfdii',
        active: true,
        display: false,
        description: '',
        assets: '',
        atp: '',
        min_investment: ''
    },
    3: {
        name: 'Caiman Stable Fund',
        symbol: 'cfds',
        active: true,
        display: true,
        description: '',
        assets: 'Stable-value pegged assets, such as DAI, USDC, USDT, UST',
        atp: '',
        min_investment: '$500,000'
    }
};

export default Funds;