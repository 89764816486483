import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import Link from '../../components/Link';
import Header from '../../components/Header';
import Team from '../../components/Team';
import FooterLarge from '../../components/FooterLarge';

const FundI = () => {
    return (
        <div>
            <Header/>
            <section className="container-fluid bg-gradient-offset mb-xl-4">
                <Container>
                    <Row>
                        <Col className="col-12 col-sm-6 pe-5">
                            <h1 className="mt-xl-5">
                                Caiman Fund I
                            </h1>
                            <p className="sub-head mt-5 mb-5 pe-xl-5">
                                The Caiman Fund I provides investors access to <strong>a diversified portfolio</strong> focused primarily on digital assets alongside <strong>more traditional investment strategies.</strong>
                            </p>
                            <div>
                                <Link className="btn btn-outline-primary"
                                      to='/deposit/cfdi'>
                                    Start&nbsp;Investing
                                </Link>
                                &nbsp;
                                <Link download
                                      className="btn btn-link link-dark fw-bold d-block d-md-inline-block"
                                      to='/assets/20210603-Caiman_Fund_I.pdf'
                                      onClick={()=>{window.open('/assets/20210603-Caiman_Fund_I.pdf');return false;}}>
                                    Download&nbsp;presentation
                                </Link>
                            </div>
                        </Col>
                        <Col className="col-12 col-sm-4 offset-sm-2 mb-5">
                            <h3>Summary of Terms</h3>
                            <dl className="d-flex justify-content-between">
                                <dt>Initial Fund Launch</dt>
                                <dd>November 2020</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Management Fee</dt>
                                <dd>2%</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Performance Fee</dt>
                                <dd>20%</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Minimum Investment</dt>
                                <dd>$100,000</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Investor Type</dt>
                                <dd>Accredited Preferred</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Subscription</dt>
                                <dd>Monthly</dd>
                            </dl>
                            <dl className="d-flex justify-content-between">
                                <dt>Redemption</dt>
                                <dd>Annual</dd>
                            </dl>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Team/>
            <section className="mt-5 pt-5 mb-5 pb-4">
                <Container>
                    <Row>
                        <Col className="col-12 col-xl-4 offset-xl-1">
                            <h5 className="mb-5 pe-lg-4">
                                The Caiman Fund I utilizes a sophisticated multi-strategy approach <strong>focused primarily on</strong> decentralized finance and a quantitative 24/7 automated algorithmic trading engine.
                            </h5>
                        </Col>
                        <Col className="col-12 col-xl-6">
                            <Row className="mt-2 ps-xl-5">
                                <Col className="col-12 col-sm-6 ps-xl-5 pe-xl-3">
                                    <p>
                                        <span className="cf-icon-fund-01 mb-4">&nbsp;</span>
                                        <strong className="d-block mb-3">Gain fast, safe exposure</strong>
                                        to Bitcoin and other blue chip assets while maximizing and compounding daily interest yields.
                                    </p>
                                </Col>
                                <Col className="col-12 col-sm-6 pe-xl-4">
                                    <p>
                                        <span className="cf-icon-fund-02 mb-4">&nbsp;</span>
                                        <strong className="d-block mb-3">Diversify across a wide range of liquidity timelines</strong>
                                        to provide a stable vehicle for investment in the blockchain ecosystem of decentralized apps, tokenomics, and other digital assets.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <FooterLarge/>
        </div>
    );
};

export default FundI;