import { Col, Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import React from 'react';
import Header from '../components/Header';

const Terms = () => {
    return (
        <div>
            <Header/>
            <Container>
                <Col className="col-12 col-sm-8 offset-0 offset-sm-2">
                    <div className="row mt-5 pt-4 mb-5 pb-5">
                        <h3>
                            Terms and Conditions
                        </h3>
                        <p>
                            These presentation materials (collectively, this “Presentation”) have been provided in order to provide a high-level overview of the Caiman Fund LLC (the “Venture Investment Manager”) and StockCroc LLC (the “Automated Algorithm Trading Manager” and together with the Venture Investment Manager, the “Investment Managers”), certain of their operational and advisory programs and processes, and certain of their key personnel.
                            This presentation is an informational document. It does not constitute an offer to sell or a solicitation to purchase any securities in the Caiman Fund I LP, a Delaware limited partnership, or any other entity organized, controlled, or managed by the Venture Investment Manager (collectively, the “Venture Funds”) and therefore may not be relied upon in connection with any offer or sale of securities. It does not constitute an offer to sell or a solicitation to purchase any securities in the Caiman Fund Ltd, Cayman Islands exempted company or any other entity organized, controlled, or managed by the Caiman Fund Management LLC (each, a “Venture Fund”, and collectively the “Venture Funds”) and therefore may not be relied upon in connection with any offer or sale of securities.
                            Any offer or solicitation may only be made pursuant to a Confidential Private Placement Memorandum (or similar document) for each Fund, which will only be provided to qualified offers and should be carefully reviewed by any such offers prior to investing. Important disclosures are included throughout this presentation and should be carefully reviewed.
                            This communication is provided for informational purposes only. In addition, because this communication is only a high-level summary, it does not contain all material information pertinent to an investment decision, including important disclosures or conflicts and risk factors associated with an investment in a Fund. This presentation in and of itself should not form the basis for an investment decision.
                            An investment in any of the Funds is speculative and entails substantial risks, including the fact that such an investment would be illiquid and subject to significant restrictions on transferability. No market is expected to develop for interests in any of the Funds.
                            Financial instruments and investment opportunities discussed or referenced herein may not be suitable for all investors, and potential investors must make an independent assessment of the appropriateness of any transaction in light of their own objectives and circumstances, including the possible risk and benefits of entering into such a transaction.
                            An investor in any of the Funds could lose all or a substantial amount of his or her investment. Returns generated rom an investment in any of the Funds may not adequately compensate investors for the business and financial risks assumed. The products and strategies in which the Funds expect to invest involve above-average risk. Please see Risk Factors section of the relevant Confidential Private Placement Memorandum (or similar document) for certain risks associated with an investment in each of the Funds.
                            This presentation and the material contained herein are confidential and may not be distributed in whole or in part to anyone other than the intended recipients. By accepting receipt of this presentation, the recipient will be deemed to represent that they possess, either individually, or through their advisors, sufficient investment expertise to understand the risks involved in any purchase or sale of any financial instruments discussed herein. Unauthorized reproduction or distribution of all or any of this material or the information contained herein is strictly prohibited.
                        </p>
                        <p>
                            Notwithstanding anything set forth herein, each recipient of this document (and each of the employees, representatives, or other agents of such recipient) may disclose to any and all persons, without limitation of any kind, the tax treatment and tax structure of (i) the Funds and (ii) any of their transactions, and all materials of any kind (including, without limitation, opinions or other tax analysis) that are provided to each recipient relating to such trap treatment and tax structure.
                            Certain information contained in this Presentation constitutes “forward-looking statements”, which can be identified by the use of forward-looking terminology such as “may”, “will”, “should”, “expect”, “target”, “project”, “estimate”, “intend”, “continue”, or “believe” or the negatives thereof or other variations thereon or comparable terminology. Due to various risks and uncertainties, actual events or results or the actual policies, procedures, and processes of the Investment Managers and the performance of the Funds may differ materially from those reflected or contemplated in such forward-looking statements and no undue reliance should be placed on these forward-looking statements, nor should the inclusion of these statements be regarded as the Investment Managers’ representation that the Funds will achieve any strategy, objectives or other plans.
                            Such information is believed to be reliable and has been obtained from sources believed to be reliable , but no representation or warranty is made, expressed, or implied with respect to the fairness, correctness, accuracy, reasonableness, or completeness of such information. In addition, there is no obligation to update, modify, or amend this Presentation or to otherwise notify a reader in the event that any matter stated heron changes or subsequently becomes inaccurate.
                            The Investment Managers are not acting and do not purport to act in any way as an advisor or in a fiduciary capacity vis-a-vis any investor in the Funds. Therefore, it is strongly suggested that any prospective investor obtain independent advice in relation to any investment, financial, legal, tax, accounting, or regulatory issues discussed herein. Analyses and opinions contained herein may be based on assumptions that if later can change the analysis’s or opinions expressed. Nothing contained herein shall constitute any representation or warranty as to the future performance of any financial instrument, credit, currency rate or other market or economic measure.
                            The distribution of this document in certain jurisdictions may be prohibited or restricted by law; therefore, people in whose possession this document comes should inform themselves about and observe such restrictions. Any such distribution could result in a violation of the law of such jurisdictions.
                            The terms summarized above are provided for informational purposes only and do not constitute a complete overview of the terms of any Fund. An investment in any Fund maybe only be made pursuant to the relevant Confidential Private Placement memorandum, which should be reviewed carefully by any such offers prior to investing.
                        </p>
                        <p>
                            W I T N E S S E T H<br/><br/>
                            WHEREAS, the Company desires to avail itself of the experience, sources of information, advice, assistance and certain facilities available to the Asset Manager and to have the Asset Manager undertake the duties and responsibilities hereinafter set forth, on behalf of, and subject to the supervision of the Board; and
                            WHEREAS, the Asset Manager is willing to undertake to render such services, subject to the supervision of the Board, on the terms and conditions set forth hereinafter.<br/>
                            NOW, THEREFORE, in consideration of the foregoing and of the mutual covenants and agreements contained herein, the parties hereto agree as follows:
                        </p>
                        <p>
                            1. Definitions. Defined terms shall have the meaning set forth below.
                            “Affiliate or Affiliated” shall mean as to any individual, corporation, partnership, limited liability company, trust or other association (i) any person or entity directly or indirectly, through one or more intermediaries controlling, controlled by, or under common control with another person or entity; (ii) any person or entity, directly or indirectly owning or controlling 10% or more of the outstanding voting securities of another person or entity; (iii) any officer, director, partner, member, or trustee of such person or entity; (iv) any person 10% or more of whose outstanding voting securities are directly or indirectly owned, controlled, or held, with power to vote, by such other person; and (v) if such other person or entity is an officer, director, partner, member, or trustee of a person or entity, the person or entity for which such person or entity acts in any such capacity. For purpose of this Agreement, the term “person” shall include any natural persons, partnership, corporation, trust, limited liability company, association or other entity.
                            “Agreement” shall mean this Asset Management Agreement between the Company and the Asset Manager, as amended from time to time.
                            “Articles of Incorporation” shall mean the charter of the Company, as amended or restated from time to time.
                            “Asset Management Fee” shall have the meaning set forth in Section 7.
                            “Asset Manager” shall mean Caiman Fund XX LLC, a Delaware limited liability company.
                            “Board” shall mean the Board of Directors of the Company.
                            “Bylaws” shall mean the bylaws of the Company, as amended from time to time.
                            “Company” shall mean StockCroc Inc., a Maryland corporation.
                            “Director” shall mean a member of the Board.
                            “GAAP” shall mean generally accepted accounting principles.
                            “Gross Assets” shall mean (i) the gross book value of the assets of the Company until such time as the Board has established a net asset value of the Company’s assets and (ii) after the Board has established a net asset value of the Company’s assets, the gross asset value of the assets of the Company based on such net asset value determination. The Gross Assets of the Company will be determined on a quarterly basis. For avoidance of doubt, under (i) or (ii), gross book value or gross asset value (as applicable) shall be determined based on the Company’s pro rata ownership interest in the underlying assets and liabilities, without regard to GAAP consolidation or equity method accounting principles.
                            “Gross Offering Proceeds” shall mean the offering proceeds received by the Company through the Offering.
                            “Investment” means any investment by the Company in Digital Related Assets.
                            “Joint Venture” means a Delaware limited liability company formed by the Operating Partnership and Caiman Fund XX, as the members, to acquire Crypto Related Assets.
                            “Multifamily Apartment Communities” means investments by the Company in cryptocurrency and digital assets, either through the Operating Partnership or through a Joint Venture.
                            Crypto Related Assets” means any investments by the Company in (i) mezzanine loans or (ii) preferred equity interests, either through the Operating Partnership or through a Joint Venture.
                            “Offering” shall mean the offering of Shares of the Company pursuant to the Offering Statement.
                            “Offering Termination Date” shall mean the date on which the earliest of the following occurs: (i) the sale of $50,000,000 of Shares, (ii) the one year anniversary of the date the SEC qualified the Offering Statement or (iii) a determination by the Board to terminate the Offering.
                            “Operating Partnership” shall mean Caiman Fund XX LP, a Delaware limited partnership.
                            “Shareholders” shall mean the registered holders of the Shares.
                            “Shares” shall mean shares of common stock of the Company.
                            “Termination Date” shall mean the date of termination of this Agreement.
                        </p>
                        <p>
                            2. Appointment. The Company hereby appoints the Asset Manager to serve as its asset manager on the terms and conditions set forth in this Agreement, and the Asset Manager hereby accepts such appointment.
                        </p>
                        <p>
                            3. Duties of the Asset Manager. The Asset Manager is responsible for advising the Company with respect to managing, operating, directing and supervising the operations and administration of the Company and its assets, including any assets owned by subsidiaries of the Company. The Asset Manager shall have no obligation to take any action that would require the Asset Manager to register as an investment advisor pursuant to the Investment Advisers Act of 1940. Subject to the limitations set forth in this Agreement, including Section 4, and the continuing and exclusive authority of the Board over the management of the Company, the Asset Manager shall perform the following duties:
                            3.1 serve as the Company’s asset manager and, when reasonably requested, provide the Board with reports in connection with the Company’s assets and investment policies;
                            3.2 provide, either directly or through access to the Asset Manager’s employees and contractors, the daily management of the Company and perform and supervise the various administrative functions reasonably necessary for the management of the Company;
                            3.3 select, and, on behalf of the Company, engage and conduct business with such persons as the Asset Manager deems necessary to the proper performance of its obligations as set forth in this Agreement, including but not limited to consultants, accountants, lenders, technical advisors, attorneys, brokers, underwriters, corporate fiduciaries, escrow agents, depositaries, custodians, agents for collection, insurers, insurance agents, banks, developers, construction companies, property owners, property managers, mortgagors, and any and all agents for any of the foregoing, including Affiliates of the Asset Manager, and persons acting in any other capacity deemed by the Asset Manager necessary or desirable for the performance of any of the foregoing services, including but not limited to entering into contracts in the name of the Company with any of the foregoing;
                            3.4 perform such due diligence and underwriting duties as reasonably required by the Board in order for the Board to make a reasonable business judgement as to the acquisition or disposition of an Investment;
                            3.5 monitor and evaluate the performance of the Company’s Investments, oversee the performance of the property managers for the Investments and coordinate and manage relationships between the Company and any Joint Venture partners;
                            3.6 provide ongoing services with respect to the management of the Company’s investments including with respect to recommendations regarding capital improvements at a Multifamily Apartment Community, payment and contestation of property and other taxes assessed at a Multifamily Apartment Community and evaluation and recommendations regarding any insurance policies obtained by the Company or a Joint Venture (or any subsidiary thereof);
                            3.7 negotiate on behalf of the Company with banks or lenders for loans to be made to the Company, or obtain loans for the Company, and negotiate on behalf of the Company with investment banking firms and broker-dealers; and provided, further, that any fees and costs payable to third parties incurred by the Asset Manager in connection with the foregoing shall be the responsibility of the Company;
                            3.8 from time to time, or at any time reasonably requested by the Board, make reports to the Board of its performance of services to the Company, as applicable, under this Agreement;
                            3.9 provide the Company with all necessary cash management services; and
                            3.10 do all things necessary to assure its ability to render the services described in this Agreement.
                        </p>
                        <p>
                            4. Authority of Asset Manager.
                            4.1 Pursuant to the terms of this Agreement (including the restrictions included in this Section 4), and subject to the continuing and exclusive authority of the Board, the Board hereby delegates to the Asset Manager the authority to perform the services described in Section 3. The Asset Manager shall have the power to delegate all or any part of its rights and powers to manage and control the business and affairs of the Company to such officers, employees, Affiliates, agents and representatives of the Asset Manager as it may deem appropriate. Any authority delegated by the Asset Manager to any other Person shall be subject to the limitations on the rights and powers of the Asset Manager specifically set forth in this Agreement.
                            4.2 Notwithstanding the foregoing, the Asset Manager may not take any action on behalf of the Company without the prior approval of the Board or duly authorized committees thereof if the Articles of Incorporation or Maryland General Corporation Law require the prior approval of the Board. The acquisition or disposition of any Investment, including any financing of such Investment, will require the prior approval of the Board, any particular Directors specified by the Board or any committee of the Board, as the case may be.
                        </p>
                        <p>
                            5. Records; Access. The Asset Manager shall maintain appropriate records of all its activities hereunder and make such records available for inspection by the Board and by counsel, auditors and authorized agents of the Company, at anytime or from time to time during normal business hours. The Asset Management Fee shall be payable monthly on the last day of such month, or the first business day following the last day of such month.
                        </p>
                        <p>
                            8. Other Services. Should the Board request that the Asset Manager or any member, manager, officer or employee thereof render services for the Company other than as set forth in Section 3, such services shall be separately compensated at such rates and in such amounts as are agreed by the Asset Manager and the Board, and shall not be deemed to be services pursuant to the terms of this Agreement.
                        </p>
                        <p>
                            9. Other Activities of the Asset Manager. Nothing herein contained shall prevent the Asset Manager from engaging in other activities, including, without limitation, the rendering of advice to other persons and the management of other programs advised, sponsored or organized by the Asset Manager or its Affiliates; nor shall this Agreement limit or restrict the right of any member, manager, officer, or employee of the Asset Manager or its Affiliates to engage in any other business or to render services of any kind to any other person. The Asset Manager may, with respect to any investment in which the Company is a participant, also render advice and service to each and every other participant therein.
                        </p>
                        <p>
                            10. Relationship of Asset Manager and Company. The Company and the Asset Manager do not intend to form a joint venture, partnership or similar relationship. Instead, the parties intend that Asset Manager shall act solely in the capacity of an independent contractor for the Company. Nothing in this Agreement shall cause the Asset Manager and the Company to be joint venturers or partners of each other, and neither shall have the power to bind or obligate the other party by virtue of this Agreement, except as expressly provided in this Agreement.
                        </p>
                        <p>
                            11. Term; Termination of Agreement. Subject to Section 12, this Agreement shall continue until December 31, 2023, which may be automatically extended for up to 4 additional 1 year periods if the term of the Company is extended as set forth in the Articles of Restatement of the Company (the “Liquidation Date”); provided, however, if the Company has listed its shares on a national exchange, over the counter exchange or the substantial equivalent thereof (as such terms are defined in the Articles of Restatement of the Company) or if the Company is involved in a merger with another entity, the Property Management Agreement shall continue until the Liquidation Date (the “Termination Date”). Notwithstanding the foregoing, the Company and the Asset Manager may extend the term of this Agreement beyond the Termination Date upon the mutual consent of the Company and the Asset Manager.
                        </p>
                        <p>
                            12. Termination for Cause. This Agreement may be terminated for cause by the Company in the event fraud, willful misconduct or gross negligence of the Asset Manager, as determined by a final, non-appealable judgement of a court of competent jurisdiction. This Agreement may be terminated for cause by the Asset Manager in the event that the Company becomes subject to regulation under the Investment Company Act.
                        </p>
                        <p>
                            13. Assignment to an Affiliate. This Agreement may be assigned by the Asset Manager to an Affiliate with the approval of the Board. The Asset Manager may assign any rights to receive fees or other payments under this Agreement without obtaining the approval of the Board. This Agreement shall not be assigned by the Company without the consent of the Asset Manager, except in the case of an assignment by the Company to a corporation or other organization which is a successor to all of the assets, rights and obligations of the Company, in which case such successor organization shall be bound hereunder and by the terms of said assignment in the same manner as the Company are bound by this Agreement.
                        </p>
                    </div>
                </Col>
            </Container>
            <Footer/>
        </div>
    );
};

export default Terms;