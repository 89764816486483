import { Col, Container, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import Link from './Link';
import Navigation from './Navigation';
import { launchLink } from '../Routes';

class Hero extends Component {

    render () {
        return (
            <section className="container-fluid bg-city">
                <Container>
                <Row className="hero">
                    <Col className="col-xl-12">
                        <Navigation isFooter={false} wallet={true} isHeader={true} />
                        <span className="p-1">&nbsp;</span>
                        <h1>
                            An exciting digital<br/>
                            frontier for the future<br/>
                            of FinTech.
                        </h1>
                        <p className="sub-head mt-5 mb-5 col-lg-6">
                            The Caiman Fund provides investors access to a <strong>diversified portfolio</strong> of <strong>blockchain focused</strong> digital assets along with more traditional investment strategies.
                        </p>
                        <Link className="btn btn-outline-primary mb-5"
                              to={launchLink()}>
                            Launch the app
                        </Link>
                    </Col>
                </Row>
                </Container>
            </section>
        );
    }
}

export default Hero;





